import React, { useEffect, useState, useReducer } from 'react';
import query from 'query-string';
import bem from 'easy-bem';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Button, Space, Popconfirm, Dropdown, Menu
} from 'antd';
import {
    PlusCircleOutlined,
    PlayCircleOutlined,
    DownloadOutlined,
    LoadingOutlined,
    DeleteOutlined,
    CopyOutlined,
    RedoOutlined,
    ReloadOutlined
} from '@ant-design/icons';

import options from 'options';

import {
    list, start, remove, copy, downloadResultsUrl
} from 'models/analysis/api';

import {
    STATUS_LOC, TYPES, STATUS, TYPES_LOC
} from 'models/analysis/constants';

import {
    filter2server
} from 'models/utils';

import Table from 'components/table';

const cn = bem('analysis-list');

const reducer = (state, action) => {
    switch (action.type) {
        case 'status-change': {
            const { id, status } = action.payload;
            return state.map(item => {
                if (item.id === id) {
                    item.status = status;
                } return item;
            });
        }

        case 'update': {
            return action.payload;
        }

        default: {
            throw new Error('No such reduce action');
        }
    }
};

const AVAILABLE_ACTIONS = {
    start: [STATUS.CREATED],
    restart: [STATUS.FAILURE],
    download: [STATUS.DONE],
    duplicate: [STATUS.CREATED, STATUS.STARTED, STATUS.PENDING, STATUS.DONE, STATUS.FAILURE, STATUS.LOADING],
    loading: [STATUS.LOADING],
    remove: [STATUS.CREATED, STATUS.STARTED, STATUS.PENDING, STATUS.DONE, STATUS.FAILURE]
};

const DOWNLOAD_MENU_ITEMS = {
    detection: 'Shape',
    geojson: 'GeoJSON',
    rgb_new: 'RGB new',
    rgb_base: 'RGB base',
    multi_temporal: 'Multi temporal'
};

const isActionAvailable = (action, status) => AVAILABLE_ACTIONS[action]?.includes(status);

export default () => {
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();

    const [data, dispatch] = useReducer(reducer, []);
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        current: 1,
        pageSize: 10,
        ...query.parse(history.location.search)
    });

    const onChangeFilter = (filter) => {
        const filterBody = filter2server(filter);
        setState(filterBody);
        history.replace(`?${query.stringify(filterBody)}`);
    };

    const { push } = useHistory();

    useEffect(() => {
        const updater = setInterval(() => onUpdate(false), options.processingUpdateInterval);
        return () => {
            clearInterval(updater);
        };
    }, [state, total]);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: 'string',
            render: (name, row) => <Link to={`/analysis/${row.id}`}> {name} </Link>,
            ellipsis: true,
            width: 400
        },
        {
            title: 'Тип',
            dataIndex: 'analysis_type',
            width: 273,
            key: 'analysis_type',
            render: text => {
                switch (text) {
                    case 'arable_analysis': return 'Факт обработки пашни';
                    case 'fire_analysis': return 'Вероятность возникновения пожара';
                    case 'wood_selection': return 'Участки для заготовки древесины';
                    case 'track_analysis': return 'Транспортная доступность лесных кварталов';
                    default: return 'Факт обработки пашни';
                }
            },
            sorter: true,
            filters: [
                { text: TYPES_LOC[TYPES.ARABLE], value: TYPES.ARABLE },
                { text: TYPES_LOC[TYPES.FIRE], value: TYPES.FIRE },
                { text: TYPES_LOC[TYPES.WOOD], value: TYPES.WOOD },
                { text: TYPES_LOC[TYPES.TRACK], value: TYPES.TRACK }
            ]
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: text => STATUS_LOC[text],
            sorter: true,
            filters: [
                { text: STATUS_LOC[STATUS.STARTED], value: STATUS.STARTED },
                { text: STATUS_LOC[STATUS.PENDING], value: STATUS.PENDING },
                { text: STATUS_LOC[STATUS.CREATED], value: STATUS.CREATED },
                { text: STATUS_LOC[STATUS.DONE], value: STATUS.DONE },
                { text: STATUS_LOC[STATUS.FAILURE], value: STATUS.FAILURE }
            ],
            width: 170
        },
        {
            title: 'Дата создания',
            dataIndex: 'created',
            key: 'created',
            render: (text) => moment(text).format(options.dateTimeFormat),
            sorter: true,
            filters: 'date-range',
            width: 186
        },
        {
            title: () => <Button type="link" shape="circle" onClick={onUpdate}> <RedoOutlined /> </Button>,
            dataIndex: ['status'],
            key: 'status',
            align: 'right',
            width: 60,
            render: (text, row) => (
                <Space className={cn('actions')}>
                    { isActionAvailable('download', text, row) && (
                        <div onClick={e => e.stopPropagation()}>
                            <Dropdown overlay={(
                                <Menu onClick={(e) => {
                                    onDownload(row.files, e.key);
                                }}>
                                    {
                                        Object.keys(row.files).map((key) => (
                                            <Menu.Item key={key}>
                                                {DOWNLOAD_MENU_ITEMS[key]}
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu>
                            )}>
                                <DownloadOutlined title="Скачать результат" onClick={e => e.stopPropagation()} />
                            </Dropdown>
                        </div>
                    )}
                    { isActionAvailable('start', text) && <PlayCircleOutlined title="Запустить" onClick={onStart(row.id)} /> }
                    { isActionAvailable('restart', text) && (
                        <Popconfirm
                            title="Вы уверены, что хотите перезапустить данный обработчик?"
                            onConfirm={onStart(row.id, true)}
                            okText="Да"
                            cancelText="Нет">
                            <ReloadOutlined title="Перезапустить" />
                        </Popconfirm>
                    ) }
                    { isActionAvailable('remove', text) && (
                        <Popconfirm
                            title="Вы уверены, что хотите удалить данную задачу на анализ?"
                            onConfirm={onRemove(row.id)}
                            okText="Да"
                            cancelText="Нет">
                            <DeleteOutlined onClick={e => e.stopPropagation()} title="Удалить" />
                        </Popconfirm>
                    ) }
                    { isActionAvailable('loading', text) && <LoadingOutlined style={{ fontSize: 24 }} spin primary /> }
                    { isActionAvailable('duplicate', text) && <CopyOutlined title="Создать дубликат" onClick={onDuplicate(row.id)} /> }
                </Space>
            )

        }
    ];

    const onStatusChange = (id, status) => {
        dispatch({
            type: 'status-change',
            payload: {
                id,
                status
            }
        });
    };

    const onDownload = (links, key) => {
        window.open(downloadResultsUrl(key, links));
    };

    const onRemove = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await remove(id);
        } finally {
            onUpdate();
        }
    };

    const onStart = (id, isForced) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await start(id, isForced);
        } finally {
            onUpdate();
        }
    };

    const onDuplicate = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await copy(id);
        } finally {
            onUpdate();
        }
    };

    const onUpdate = async (showLoader = true) => {
        try {
            setLoading(showLoader);
            const { data } = await list(state);
            const { values, total } = data;
            setTotal(total);
            dispatch({
                type: 'update',
                payload: values
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onUpdate();
    }, [state]);

    return (
        <div className={cn()}>
            <Space className={cn('instruments')}>
                <Button onClick={() => push('/analysis/add')} type="primary" icon={<PlusCircleOutlined />}> Добавить пространственный анализ </Button>
            </Space>
            <div className={cn('content')}>
                <Table
                    getPopupContainer={(trigger) => trigger.parentElement}
                    key="id"
                    rowClassName={cn('row')}
                    onChange={(pages, filters, sort) => {
                        onChangeFilter({
                            pages,
                            filters,
                            sort
                        });
                    }}
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        total,
                        current: Number(state.current)
                    }}
                    dataSource={data.map((item, idx) => ({
                        key: idx,
                        ...item
                    }))} />
            </div>
        </div>
    );
};
