import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Select,
    Space,
    Spin,
    Typography,
    Upload,
    Tooltip,
    Slider
} from 'antd';
import {
    LoadingOutlined,
    UploadOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router';
import bem from 'easy-bem';

import options from 'options';
import { change } from 'models/interval-analysis/api';
import { normalizeFile } from 'utils/normalize';
import moment from 'moment';
import { inputValidator } from '../../../workrers/methods';
import { zeroDate } from '../../../../models/utils';
import { everyStartsValidator } from '../../functions';
import { EDIT_STATUS_LIST } from '../../constants';

const cn = bem('interval-analysis-add');
const dateFormat = 'YYYY-MM-DD';

const { TextArea } = Input;
        
export default ({ item }) => {
    const { push } = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);
    const isDisabled = !EDIT_STATUS_LIST.includes(item.status);

    const normalizeValues = React.useMemo(() => ({
        ...item
    }), [item]);

    const onCancel = () => {
        push('/interval-analysis');
    };

    const onReset = () => {
        form.resetFields();
    };

    let isSubmitting = false;

    const onSubmit = async (values) => {
        debugger;
        try {
            if (isSubmitting) return;
            isSubmitting = true;

            const body = {
                ...values
            };

            if (values.area_of_interest) {
                body.area_of_interest = values?.area_of_interest[0];
            }
            
            delete body.target_date;
            if (values.target_date) {
                const [from_date, to_date] = values.target_date;
                body.to_date = to_date.format(dateFormat);
                body.from_date = from_date.format(dateFormat);
            }

            await change(item.id, body);
            push('/interval-analysis');
        } finally {
            isSubmitting = false;
            setLoading(false);
        }
    };

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    ID: { useParams().id }
                </Typography.Title>
                <Form
                    initialValues={normalizeValues}
                    form={form}
                    layout="vertical"
                    name="analysis"
                    onFinish={onSubmit}
                    scrollToFirstError>

                    <div className={cn('block')}>
                        <Form.Item
                            name="name"
                            label="Название"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле не задано',
                                    type: 'string',
                                    validator: isDisabled ? undefined : inputValidator
                                }
                            ]}>
                            <TextArea style={{ resize: 'none' }} showCount maxLength={100} disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            name="analysis_type"
                            label="Выберите тип"
                            rules={[{ required: true, message: 'Поле не задано' }]}>
                            <Select getPopupContainer={(trigger) => trigger.parentElement} disabled={isDisabled}>
                                <Select.Option value="fire_analysis">
                                    {' '}
                  Вероятность возникновения пожара{' '}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            shouldUpdate={(prevValues, values) =>
                                prevValues.analysis_type !== values.analysis_type
                                || prevValues.start_every_days !== values.start_every_days
                                || prevValues.merge_every_starts !== values.merge_every_starts}>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('analysis_type');
                                const startEveryDays = getFieldValue('start_every_days');
                                const mergeEveryStarts = getFieldValue('merge_every_starts');

                                if (type !== 'fire_analysis') return null;

                                return (
                                    <div>
                                        <Form.Item
                                            name="area_of_interest"
                                            label="Выберете данные для периодического анализа"
                                            valuePropName="fileList"
                                            getValueFromEvent={normalizeFile}>
                                            <Upload
                                                accept=".zip"
                                                name="area_of_interest"
                                                beforeUpload={() => false}
                                                listType="text">
                                                <Button type="primary" disabled={isDisabled}>
                                                    <UploadOutlined /> Границы лесных участков
                                                </Button>
                                            </Upload>
                                            <Form.Item>
                                                <h5> {item.current_area_of_interest} </h5>
                                            </Form.Item>
                                        </Form.Item>
                                        <Divider> Период пространственного анализа </Divider>
                                        <Form.Item name="target_date"
                                            label="Начало и конец периода"
                                            rules={[{ required: true, message: 'Поле не задано' }]}>
                                            <DatePicker.RangePicker
                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                format={options.dateFormat}
                                                disabledDate={d => !d || d.isBefore(zeroDate)}
                                                disabled={isDisabled}
                                                placeholder={['Выберите дату', 'Выберите дату']} />
                                        </Form.Item>
                                        <Form.Item
                                            name="start_every_days"
                                            label={(
                                                <div className={cn('title-and-tooltip')}>
                                                    <span>Периодичность запуска</span>
                                                    <Tooltip placement="right" title="«Периодичность запуска»*«Количество объединенных анализов»= от 1 до 7”">
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'number',
                                                    validator: (_, value) => everyStartsValidator(value, mergeEveryStarts)
                                                }
                                            ]}>
                                            <InputNumber controls={false} placeholder="Допустимое значение от 1 до 7" disabled={isDisabled} />
                                        </Form.Item>
                                        <Form.Item
                                            name="merge_every_starts"
                                            label={(
                                                <div className={cn('title-and-tooltip')}>
                                                    <span>Количество объединенных анализов</span>
                                                    <Tooltip placement="right" title="«Периодичность запуска»*«Количество объединенных анализов»= от 1 до 7”">
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'number',
                                                    validator: (_, value) => everyStartsValidator(value, startEveryDays)
                                                }
                                            ]}>
                                            <InputNumber controls={false} placeholder="Допустимое значение от 1 до 7" disabled={isDisabled} />
                                        </Form.Item>
                                    </div>
                                );
                            }}
                        </Form.Item>
                    </div>

                    <Divider />

                    <Form.Item>
                        <Space>
                            <Button disabled={isDisabled} type="primary" htmlType="submit">
                Редактировать
                            </Button>
                            <Button onClick={onCancel}>Отмена</Button>

                            <Button danger type="link" onClick={onReset}>
                Сбросить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};
