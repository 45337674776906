import React from 'react';
import {
    Button, Divider, Form, Input, Space, Spin, Typography
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import bem from 'easy-bem';

import { edit } from 'models/users/api';

const cn = bem('users-edit');

export default () => {
    const { push } = useHistory();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);

    const onCancel = () => {
        push('/users');
    };

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const body = {
                ...values
            };
            delete body.confirm;
            await edit(id, body);
            onCancel();
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    Редактирование пользователя
                </Typography.Title>
                <Form
                    form={form}
                    layout="vertical"
                    name="user-change"
                    onFinish={onSubmit}
                    scrollToFirstError>
                    <Divider> Смена пароля </Divider>
                    <div className={cn('block')}>
                        <Form.Item
                            name="password"
                            label="Пароль"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Повторите пароль"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, подтвердите пароль'
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Пароли не совпадают');
                                    }
                                })
                            ]}>
                            <Input.Password />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Изменить
                            </Button>
                            <Button onClick={onCancel}>
                                Отмена
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};