import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.less';

export default () => {
    return (
        <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
        </div>
    );
};