import React from 'react';
import {
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    Select,
    Space,
    Spin,
    Typography,
    Upload,
    Tooltip,
    Slider
} from 'antd';
import { LoadingOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import bem from 'easy-bem';

import options from 'options';
import { create } from 'models/analysis/api';
import { normalizeFile } from 'utils/normalize';
import moment from 'moment';
import { getDateFromNow, zeroDate } from '../../../models/utils';
import { inputValidator } from '../../workrers/methods';

const cn = bem('analysis-add');
const dateFormat = 'YYYY-MM-DD';
const defaultValues = {
    select_age: 0,
    select_density: 0,
    select_merch: 0,
    select_slope: 0,
    select_stock: 0
};

const { TextArea } = Input;

export default () => {
    const { push } = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);

    const onCancel = () => {
        push('/analysis');
    };

    const onReset = () => {
        form.resetFields();
    };

    let isSubmitting = false;

    const onSubmit = async (values) => {
        try {
            if (isSubmitting) return;
            isSubmitting = true;
            if (values.from_date) {
                values.from_date = values.from_date.format(dateFormat);
                values.to_date = values.from_date;
            }
              
            if (values.passport_path) {
                values.passport_path = values?.passport_path[0];
            } else {
                delete values.passport_path;
            }
            if (values.mask_path) {
                values.mask_path = values?.mask_path[0];
            } else {
                delete values.mask_path;
            }
            if (values.analysis_type === 'wood_selection') {
                const extra = {
                    select_age: values.select_age,
                    select_density: values.select_density,
                    select_merch: values.select_merch,
                    select_slope: values.select_slope,
                    select_stock: values.select_stock
                };
                values.extra = JSON.stringify(extra);
                delete values.select_age;
                delete values.select_density;
                delete values.select_merch;
                delete values.select_slope;
                delete values.select_stock;
            }
            await create(values);
            push('/analysis');
        } catch {
            isSubmitting = false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    Добавить пространственный анализ
                </Typography.Title>
                <Form
                    initialValues={defaultValues}
                    form={form}
                    layout="vertical"
                    name="analysis"
                    onFinish={onSubmit}
                    scrollToFirstError>
                    <Divider> Основные настройки </Divider>

                    <div className={cn('block')}>
                        <Form.Item
                            name="name"
                            label="Укажите название задачи на анализ"
                            rules={[{
                                required: true,
                                message: 'Поле не задано',
                                type: 'string',
                                validator: inputValidator
                            }]}>
                            <TextArea style={{ resize: 'none' }} showCount maxLength={100} />
                        </Form.Item>
                        <Form.Item name="analysis_type"
                            label="Выберите тип"
                            rules={[{ required: true, message: 'Поле не задано' }]}>
                            <Select getPopupContainer={(trigger) => trigger.parentElement}>
                                <Select.Option value="arable_analysis"> Факт обработки пашни </Select.Option>
                                <Select.Option value="fire_analysis"> Вероятность возникновения пожара </Select.Option>
                                <Select.Option value="wood_selection"> Участки для заготовки древесины </Select.Option>
                                <Select.Option value="track_analysis"> Транспортная доступность лесных кварталов </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            shouldUpdate={
                                (prevValues, values) => prevValues.analysis_type !== values.analysis_type
                            }>
                                
                            {({ getFieldValue }) => {
                                const type = getFieldValue('analysis_type');

                                if (!type) return null;
                                
                                return (
                                    <Form.Item
                                        shouldUpdate={
                                            (prevValues, values) => prevValues.analysis_type !== values.analysis_type
                                        }
                                        label="Выберите данные для анализа"
                                        rules={[{ required: true }]}>
                                        {() => {
                                            if (type === 'arable_analysis') {
                                                return (
                                                    <div>
                                                        <Form.Item
                                                            name="passport_path"
                                                            rules={[{ required: true, message: 'Пожалуйста, загрузите данные для анализа' }]}
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="passport_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button type="primary">
                                                                    <UploadOutlined /> Границы полей
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="mask_path"
                                                            rules={[{ required: true, message: 'Пожалуйста, загрузите данные для анализа' }]}
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="mask_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button type="primary">
                                                                    <UploadOutlined /> Используемые пашни
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </div>
                                        
                                                );
                                            } else if (type === 'fire_analysis') {
                                                return (
                                                    <div>
                                                        <Form.Item
                                                            name="mask_path"
                                                            rules={[{ required: true, message: 'Пожалуйста, загрузите данные для анализа' }]}
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="mask_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button type="primary">
                                                                    <UploadOutlined /> Границы лесных участков
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>

                                                        <Form.Item name="from_date"
                                                            label={(
                                                                <div className={cn('title-and-tooltip')}>
                                                                    <span>Дата анализа</span>
                                                                    <Tooltip placement="right" title="Для выбора доступны числа не более 7 дней от текущей даты">
                                                                        <QuestionCircleOutlined />
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            rules={[{ required: true, message: 'Поле не задано' }]}>
                                                            <DatePicker
                                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                                disabledDate={d => !d
                                                                    || d.isBefore(zeroDate)
                                                                    || d.isAfter(getDateFromNow(8, dateFormat))}
                                                                format={options.dateFormat}
                                                                placeholder="Укажите дату" />
                                                        </Form.Item>
                                                        
                                                    </div>
                                        
                                                );
                                            } else if (['wood_selection', 'track_analysis'].includes(type)) {
                                                return (
                                                    <Form.Item
                                                        name="mask_path"
                                                        rules={[{ required: true, message: 'Пожалуйста, загрузите данные для анализа' }]}
                                                        valuePropName="fileList"
                                                        getValueFromEvent={normalizeFile}>
                                                        <Upload
                                                            accept=".zip"
                                                            name="mask_path"
                                                            beforeUpload={() => false}
                                                            listType="text">
                                                            <Button type="primary">
                                                                <UploadOutlined /> Границы лесных участков
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                        
                                                );
                                            }

                                            return null;
                                        }}
                                    </Form.Item>
                                        
                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.analysis_type !== values.analysis_type
                            }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('analysis_type');
                                if (!['wood_selection'].includes(type)) return null;
                                return (
                                    <div>
                                        <Form.Item name="select_age"
                                            label="Возраст"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_density"
                                            label="Полнота"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_merch"
                                            label="Класс товарности"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_slope"
                                            label="Крутизна склона"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_stock"
                                            label="Запас на га, м куб"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                    </div>
                                    
                                );
                            }}
                        </Form.Item>
                    </div>

                    <Divider />

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Создать
                            </Button>
                            <Button onClick={onCancel}>
                                Отмена
                            </Button>

                            <Button danger type="link" onClick={onReset}>
                                Сбросить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};
