import React from 'react';
import { Select } from 'antd';

export default ({ children, source, ...props }) => {
    const [options, setOptions] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!source) return;
        setLoading(true);
        source()
            .then((res) => {
                const { data } = res;
                setOptions(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [source]);

    return (
        <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            loading={isLoading}
            {...props}>
            {
                options.map(({ id, title }) => (
                    <Select.Option value={id} key={id}>{title}</Select.Option>
                ))
            }
        </Select>
    );
};