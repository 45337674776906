import React, { useEffect, useState, useReducer } from 'react';
import query from 'query-string';
import bem from 'easy-bem';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Button, Space, Popconfirm, Dropdown, Menu
} from 'antd';
import {
    DesktopOutlined,
    ToolOutlined,
    ReloadOutlined,
    PlayCircleOutlined,
    PauseOutlined,
    DownloadOutlined,
    LoadingOutlined,
    DeleteOutlined,
    CopyOutlined,
    RedoOutlined
} from '@ant-design/icons';

import options from 'options';

import {
    list, start, stop, remove, copy, downloadResultsUrl
} from 'models/workers/api';

import {
    STATUS_LOC, TYPES, STATUS, TYPES_LOC
} from 'models/workers/constants';

import {
    filter2server
} from 'models/utils';

import Table from 'components/table';

const cn = bem('workers-list');

const reducer = (state, action) => {
    switch (action.type) {
        case 'status-change': {
            const { id, status } = action.payload;
            return state.map(item => {
                if (item.id === id) {
                    item.status.status = status;
                } return item;
            });
        }

        case 'update': {
            return action.payload;
        }

        default: {
            throw new Error();
        }
    }
};

const AVAILABLE_ACTIONS = {
    start: [STATUS.CREATED],
    download: [STATUS.DONE],
    restart: [STATUS.STARTED, STATUS.STOPPED, STATUS.FAILURE],
    duplicate: [STATUS.CREATED, STATUS.STARTED, STATUS.STOPPED, STATUS.DONE, STATUS.FAILURE, STATUS.LOADING],
    stop: [STATUS.STARTED],
    loading: [STATUS.LOADING],
    remove: [STATUS.CREATED, STATUS.STOPPED, STATUS.DONE, STATUS.FAILURE]
};

const DOWNLOAD_MENU_ITEMS = {
    detection: 'Shape',
    geojson: 'GeoJSON',
    rgb_new: 'RGB new',
    rgb_base: 'RGB base',
    multi_temporal: 'Multi temporal'
};

const isActionAvailable = (action, status) => AVAILABLE_ACTIONS[action]?.includes(status);

export default () => {
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();

    const [data, dispatch] = useReducer(reducer, []);
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        current: 1,
        pageSize: 10,
        ...query.parse(history.location.search)
    });

    const onChangeFilter = (filter) => {
        const filterBody = filter2server(filter);
        setState(filterBody);
        history.replace(`?${query.stringify(filterBody)}`);
    };

    const { push } = useHistory();

    useEffect(() => {
        const updater = setInterval(() => onUpdate(false), options.processingUpdateInterval);
        return () => {
            clearInterval(updater);
        };
    }, [state, total]);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: 'Тип',
            dataIndex: 'creation_type',
            width: 60,
            key: 'creation_type',
            render: text =>
                (text === TYPES.AUTO
                    ? <DesktopOutlined title={TYPES_LOC[text]} />
                    : <ToolOutlined title={TYPES_LOC[text]} />),
            sorter: true,
            filters: [
                { text: TYPES_LOC[TYPES.AUTO], value: TYPES.AUTO },
                { text: TYPES_LOC[TYPES.MANUAL], value: TYPES.MANUAL }
            ]
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: 'string',
            render: (name, row) => <Link to={`/workers/${row.id}`}> {name} </Link>,
            width: 400,
            ellipsis: true
        },
        {
            title: 'Статус',
            dataIndex: ['status', 'status'],
            key: 'status.status',
            render: text => STATUS_LOC[text],
            sorter: true,
            filters: [
                { text: STATUS_LOC[STATUS.STARTED], value: STATUS.STARTED },
                { text: STATUS_LOC[STATUS.CREATED], value: STATUS.CREATED },
                { text: STATUS_LOC[STATUS.DONE], value: STATUS.DONE },
                { text: STATUS_LOC[STATUS.STOPPED], value: STATUS.STOPPED },
                { text: STATUS_LOC[STATUS.FAILURE], value: STATUS.FAILURE },
                { text: STATUS_LOC[STATUS.DELETED], value: STATUS.DELETED },
                { text: STATUS_LOC[STATUS.NO_IMAGES], value: STATUS.NO_IMAGES },
                { text: STATUS_LOC[STATUS.UPDATE], value: STATUS.UPDATE },
                { text: STATUS_LOC[STATUS.NO_RESULTS], value: STATUS.NO_RESULTS },
                { text: STATUS_LOC[STATUS.WAIT_FOR_TIME], value: STATUS.WAIT_FOR_TIME }
            ],
            width: 170
        },
        {
            title: 'Выполнение',
            dataIndex: 'status',
            key: 'status',
            render: (text, row) => {
                if (row.status.stage === null && row.status.total_stages === null) {
                    return false;
                }
                return `${row.status.stage ?? 0} из ${row.status.total_stages ?? 0}`;
            },
            width: 170
        },
        {
            title: 'Дата создания',
            dataIndex: 'created',
            key: 'created',
            render: (text, row) => moment(text).format(options.dateTimeFormat),
            sorter: true,
            filters: 'date-range',
            width: 170
        },
        {
            title: () => <Button type="link" shape="circle" onClick={onUpdate}> <RedoOutlined /> </Button>,
            dataIndex: ['status', 'status'],
            key: 'actions',
            align: 'right',
            width: 120,
            render: (text, row) => (
                <Space className={cn('actions')}>
                    { isActionAvailable('download', text) && (
                        <div onClick={e => e.stopPropagation()}>
                            <Dropdown overlay={(
                                <Menu onClick={(e) => {
                                    onDownload(row.files, e.key);
                                }}>
                                    {
                                        Object.keys(row.files).map((key) => (
                                            <Menu.Item key={key}>
                                                {DOWNLOAD_MENU_ITEMS[key]}
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu>
                            )}>
                                <DownloadOutlined title="Скачать результат" onClick={e => e.stopPropagation()} />
                            </Dropdown>
                        </div>
                    )}
                    { isActionAvailable('start', text) && <PlayCircleOutlined title="Запустить" onClick={onStart(row.id)} /> }
                    { isActionAvailable('restart', text) && (
                        <Popconfirm
                            title="Вы уверены, что хотите перезапустить данный обработчик?"
                            onConfirm={onStart(row.id, true)}
                            okText="Да"
                            cancelText="Нет">
                            <ReloadOutlined title="Перезапустить" />
                        </Popconfirm>
                    ) }
                    { isActionAvailable('stop', text) && <PauseOutlined title="Остановить" onClick={onStop(row.id)} /> }
                    { isActionAvailable('remove', text) && (
                        <Popconfirm
                            title="Вы уверены, что хотите удалить данный обработчик?"
                            onConfirm={onRemove(row.id)}
                            okText="Да"
                            cancelText="Нет">
                            <DeleteOutlined onClick={e => e.stopPropagation()} title="Удалить" />
                        </Popconfirm>
                    ) }
                    { isActionAvailable('loading', text) && <LoadingOutlined style={{ fontSize: 24 }} spin primary /> }
                    { isActionAvailable('duplicate', text) && <CopyOutlined title="Создать дубликат" onClick={onDuplicate(row.id)} /> }
                </Space>
            )

        }
    ];

    const onStatusChange = (id, status) => {
        dispatch({
            type: 'status-change',
            payload: {
                id,
                status
            }
        });
    };

    const onDownload = (links, key) => {
        window.open(downloadResultsUrl(key, links));
    };

    const onRemove = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await remove(id);
        } finally {
            onUpdate();
        }
    };

    const onStop = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await stop(id);
        } finally {
            onUpdate();
        }
    };

    const onStart = (id, isForce) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await start(id, isForce);
        } finally {
            onUpdate();
        }
    };

    const onDuplicate = (id, isForce) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await copy(id);
        } finally {
            onUpdate();
        }
    };

    const onUpdate = async (showLoader = true) => {
        try {
            setLoading(showLoader);
            const { data } = await list(state);
            const { values, total } = data;
            setTotal(total);
            dispatch({
                type: 'update',
                payload: values
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onUpdate();
    }, [state]);

    return (
        <div className={cn()}>
            <Space className={cn('instruments')}>
                <Button onClick={() => push('/workers/add/auto')} type="primary" icon={<DesktopOutlined />}> Добавить автоматический обработчик </Button>
                <Button onClick={() => push('/workers/add/manual')} type="primary" icon={<ToolOutlined />}> Добавить ручной обработчик </Button>
                <Button onClick={() => window.open(options.resultUrl)}> Результаты </Button>
                <Button onClick={() => window.open(options.monitoringUrl)}> Мониторинг </Button>
            </Space>
            <div className={cn('content')}>
                <Table
                    getPopupContainer={(trigger) => trigger.parentElement}
                    key="id"
                    rowClassName={cn('row')}
                    onChange={(pages, filters, sort) => {
                        onChangeFilter({
                            pages,
                            filters,
                            sort
                        });
                    }}
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        total,
                        current: Number(state.current)
                    }}
                    dataSource={data.map((item, idx) => ({
                        key: idx,
                        ...item
                    }))} />
            </div>
        </div>
    );
};
