export const STATUS = {
    CREATED: 'created',
    STARTED: 'started',
    PENDING: 'pending',
    DONE: 'done',
    FAILURE: 'failed',
    LOADING: 'loading'
};

export const STATUS_LOC = {
    [STATUS.CREATED]: 'Создан',
    [STATUS.STARTED]: 'Активен',
    [STATUS.PENDING]: 'Ожидание',
    [STATUS.DONE]: 'Выполнен',
    [STATUS.FAILURE]: 'Ошибка',
    [STATUS.LOADING]: 'Загрузка'
};

export const TYPES = {
    ARABLE: 'arable_analysis',
    FIRE: 'fire_analysis',
    WOOD: 'wood_selection',
    TRACK: 'track_analysis'
};
export const TYPES_LOC = {
    [TYPES.ARABLE]: 'Факт обработки пашни',
    [TYPES.FIRE]: 'Вероятность возникновения пожара',
    [TYPES.WOOD]: 'Участки для заготовки древесины',
    [TYPES.TRACK]: 'Транспортная доступность лесных кварталов'
};

export const CREATION_TYPES = {
    ARBITRARY: 'arbitrary',
    PROCESSING: 'processing'
};
