import React from 'react';
import {
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    Select,
    Space,
    Spin,
    Typography,
    Upload,
    Tooltip,
    Slider
} from 'antd';
import { LoadingOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import bem from 'easy-bem';

import options from 'options';
import { change } from 'models/analysis/api';

import { normalizeFile } from 'utils/normalize';

import { EDIT_STATUS_LIST } from '../../constants';
import { inputValidator } from '../../../workrers/methods';

const cn = bem('analysis-add');
const dateFormat = 'YYYY-MM-DD';

const { TextArea } = Input;

export default ({ item }) => {
    const { push } = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);
    const isDisabled = !EDIT_STATUS_LIST.includes(item.status);

    const onCancel = () => {
        push('/analysis');
    };

    const onReset = () => {
        form.resetFields();
    };

    const normalizeValues = React.useMemo(() => ({
        ...item
    }), [item]);

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const {
                from_date,
                to_date,
                ...body
            } = values;

            if (from_date) {
                body.from_date = from_date.format(dateFormat);
            }

            if (body.passport_path) {
                body.passport_path = body?.passport_path[0];
            } else {
                delete body.passport_path;
            }

            if (body.mask_path) {
                body.mask_path = body?.mask_path[0];
            } else {
                delete body.mask_path;
            }

            if (body.analysis_type === 'wood_selection') {
                const extra = {
                    select_age: body.select_age,
                    select_density: body.select_density,
                    select_merch: body.select_merch,
                    select_slope: body.select_slope,
                    select_stock: body.select_stock
                };
                body.extra = JSON.stringify(extra);
                delete body.select_age;
                delete body.select_density;
                delete body.select_merch;
                delete body.select_slope;
                delete body.select_stock;
            }

            await change(item.id, body);
        } finally {
            setLoading(false);
            push('/analysis');
        }
    };

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    ID: {item.id}
                </Typography.Title>
                <Form
                    initialValues={normalizeValues}
                    form={form}
                    layout="vertical"
                    name="auto-worker"
                    onFinish={onSubmit}
                    scrollToFirstError>
                    <Divider> Основные настройки </Divider>
                    
                    <div className={cn('block')}>
                        <Form.Item
                            name="name"
                            label="Укажите название задачи на анализ"
                            rules={[{
                                required: true,
                                message: 'Поле не задано',
                                type: 'string',
                                validator: isDisabled ? undefined : inputValidator
                            }]}>
                            <TextArea style={{ resize: 'none' }} showCount maxLength={100} disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item name="analysis_type"
                            label="Выберите тип"
                            rules={[{ required: true, message: 'Поле не задано' }]}>
                            <Select
                                getPopupContainer={(trigger) => trigger.parentElement}
                                disabled>
                                <Select.Option value="arable_analysis"> Факт обработки пашни </Select.Option>
                                <Select.Option value="fire_analysis"> Вероятность возникновения пожара </Select.Option>
                                <Select.Option value="wood_selection"> Участки для заготовки древесины </Select.Option>
                                <Select.Option value="track_analysis"> Транспортная доступность лесных кварталов </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            shouldUpdate={
                                (prevValues, values) => prevValues.analysis_type !== values.analysis_type
                            }>
                                
                            {({ getFieldValue }) => {
                                const type = getFieldValue('analysis_type');

                                if (!type) return null;
                                
                                return (
                                    <Form.Item
                                        shouldUpdate={
                                            (prevValues, values) => prevValues.analysis_type !== values.analysis_type
                                        }
                                        label="Выберите данные для анализа"
                                        rules={[{ required: true }]}>
                                        {() => {
                                            if (type === 'arable_analysis') {
                                                return (
                                                    <div>
                                                        <Form.Item
                                                            name="passport_path"
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="passport_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button disabled={isDisabled} type="primary">
                                                                    <UploadOutlined /> Границы полей
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <h5> {item.current_passport_path} </h5>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="mask_path"
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="mask_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button disabled={isDisabled} type="primary">
                                                                    <UploadOutlined /> Используемые пашни
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <h5> {item.current_mask_path} </h5>
                                                        </Form.Item>
                                                    </div>
                                                );
                                            } else if (type === 'fire_analysis') {
                                                return (
                                                    <div>
                                                        <Form.Item
                                                            name="mask_path"
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="mask_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button disabled={isDisabled} type="primary">
                                                                    <UploadOutlined /> Границы лесных участков
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <h5> {item.current_mask_path} </h5>
                                                        </Form.Item>

                                                        <Form.Item name="from_date"
                                                            label={(
                                                                <div className={cn('title-and-tooltip')}>
                                                                    <span>Дата анализа</span>
                                                                    <Tooltip placement="right" title="Для выбора доступны числа не более 7 дней от текущей даты">
                                                                        <QuestionCircleOutlined />
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            rules={[{ required: true, message: 'Поле не задано' }]}>
                                                            <DatePicker
                                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                                disabled
                                                                format={options.dateFormat}
                                                                placeholder="Укажите дату" />
                                                        </Form.Item>
                                                    </div>
                                                );
                                            } else if (['wood_selection', 'track_analysis'].includes(type)) {
                                                return (
                                                    <div>
                                                        <Form.Item
                                                            name="mask_path"
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normalizeFile}>
                                                            <Upload
                                                                accept=".zip"
                                                                name="mask_path"
                                                                beforeUpload={() => false}
                                                                listType="text">
                                                                <Button disabled={isDisabled} type="primary">
                                                                    <UploadOutlined /> Границы лесных участков
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <h5> {item.current_mask_path} </h5>
                                                        </Form.Item>
                                                    </div>
                                                );
                                            }

                                            return null;
                                        }}
                                    </Form.Item>
                                        
                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.analysis_type !== values.analysis_type
                            }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('analysis_type');
                                if (!['wood_selection'].includes(type)) return null;
                                return (
                                    <div>
                                        <Form.Item name="select_age"
                                            label="Возраст"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_density"
                                            label="Полнота"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_merch"
                                            label="Класс товарности"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_slope"
                                            label="Крутизна склона"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name="select_stock"
                                            label="Запас на га, м куб"
                                            rules={[{
                                                type: 'number',
                                                max: 1,
                                                min: 0
                                            }]}>
                                            <Slider min={0} max={1} step={0.1} />
                                        </Form.Item>
                                    </div>
                                    
                                );
                            }}
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Редактировать
                            </Button>
                            <Button onClick={onCancel}>
                                Отмена
                            </Button>
                            <Button danger type="link" onClick={onReset}>
                                Сбросить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};
