import React from 'react';
import Private from 'containers/private';
import Component from './component';

export default (
    <Private
        key="interval-analysis"
        path="/interval-analysis"
        component={Component} />
);
