import React, {
    useEffect, useState, useReducer
} from 'react';
import axios from 'axios';

import { message } from 'antd';

import Loading from 'components/loader';
import { current, login, logout } from 'models/users/api';
import { setToken, getToken } from 'utils/utils';

import Auth from 'contexts/auth';

import './style.less';

function reducer(state, action) {
    switch (action.type) {
        case 'CHECK':
            return { ...action.payload, isAuth: true };
        case 'LOGOUT':
            return {};
        default:
            throw new Error();
    }
}

export default ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {});
    const [isLoading, setLoading] = useState(true);

    const onCheckAuth = async () => {
        setLoading(true);
        axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
        try {
            const { data } = await current();
            dispatch({
                type: 'CHECK',
                payload: data
            });
        } finally {
            setLoading(false);
        }
    };

    const onLogin = async (body) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const { data } = await login(body);
            axios.defaults.headers.common = { Authorization: `Bearer ${data.access_token}` };
            setToken(data.access_token);
            await onCheckAuth();
            return data;
        } catch (e) {
            throw e;
        }
    };

    const onLogout = async () => {
        setLoading(true);
        try {
            await logout();
            dispatch({
                type: 'LOGOUT'
            });
            axios.defaults.headers.common = { Authorization: `Bearer ${undefined}` };
            setToken(undefined);
        } catch (e) {
            message.error('Не удалось выполнить выход из системы');
        } finally {
            setLoading(false);
            window.location.reload();
        }
    };

    useEffect(() => {
        onCheckAuth();
    }, []);

    if (isLoading) {
        return (
            <Loading />
        );
    }

    return (
        <Auth.Provider value={{
            user: state,
            dispatch,
            onLogout,
            onLogin
        }}>
            { children }
        </Auth.Provider>
    );
};
