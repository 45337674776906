import React from 'react';
import { useParams, useHistory } from 'react-router';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import moment from 'moment';

import { get } from 'models/analysis/api';

import Item from './item';

const cn = bem('analysis-item');

export default () => {
    const [item, setItem] = React.useState();
    const { id } = useParams();
    const { push } = useHistory();

    const init = async () => {
        try {
            const { data } = await get(id);
            const item = { ...data };

            item.from_date = moment(item.from_date);

            item.current_passport_path = item.passport_path;
            delete item.passport_path;

            item.current_mask_path = item.mask_path;
            delete item.mask_path;

            item.select_age = item.extra.select_age;
            item.select_density = item.extra.select_density;
            item.select_merch = item.extra.select_merch;
            item.select_slope = item.extra.select_slope;
            item.select_stock = item.extra.select_stock;

            setItem(item);
        } catch (e) {
            push('/analysis');
        }
    };

    React.useEffect(() => {
        init();
    }, []);

    if (item) {
        return <Item item={item} />;
    }

    return (
        <Spin className={cn('loader')} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
    );
};
