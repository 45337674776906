import React, { useEffect, useState, useReducer } from 'react';
import bem from 'easy-bem';
import { useHistory } from 'react-router';
import { Button, Space, Popconfirm } from 'antd';
import {
    UserAddOutlined,
    DeleteOutlined,
    EditOutlined, RedoOutlined
} from '@ant-design/icons';

import {
    list, remove
} from 'models/users/api';

import Table from 'components/table';

const cn = bem('users-list');

const reducer = (state, action) => {
    switch (action.type) {
        case 'update': {
            return action.payload;
        }
        case 'remove': {
            return state.filter(({ id }) => id !== action.payload);
        }
        default: {
            return state;
        }
    }
};

export default () => {
    const [isLoading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        pages: {
            current: 1,
            pageSize: 10
        }
    });
    const [data, dispatch] = useReducer(reducer, []);

    const { push } = useHistory();

    const columns = [
        {
            title: 'Логин',
            dataIndex: 'login',
            key: 'login',
            sorter: true,
            filters: 'string',
            render: (text = 'Неизвестно') => text
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            filters: 'string',
            render: (text = 'Неизвестно') => text
        },
        {
            title: 'Фамилия',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: true,
            filters: 'string',
            render: (text = 'Неизвестно') => text
        },
        {
            title: 'Имя',
            dataIndex: 'surname',
            key: 'surname',
            sorter: true,
            filters: 'string',
            render: (text = 'Неизвестно') => text
        },
        {
            title: 'Отчество',
            dataIndex: 'middle_name',
            key: 'middle_name',
            sorter: true,
            filters: 'string',
            render: (text = 'Неизвестно') => text
        },
        {
            dataIndex: 'id',
            key: 'actions',
            align: 'right',
            title: () => <RedoOutlined onClick={onUpdate} />,
            render: (id) => (
                <Space className={cn('actions')}>
                    <EditOutlined
                        title="Смена пароля"
                        onClick={() => push(`users/${id}`)} />
                    <Popconfirm
                        title="Вы уверены, что хотите удалить данного пользователя?"
                        onConfirm={onRemove(id)}
                        okText="Да"
                        cancelText="Нет">
                        <DeleteOutlined title="Удалить" />
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const onStatusChange = (id, status) => {
        dispatch({
            type: 'status-change',
            payload: {
                id,
                status
            }
        });
    };

    const onRemove = (id) => async () => {
        try {
            onStatusChange(id, 'loading');
            await remove(id);
            dispatch({
                type: 'remove',
                payload: id
            });
        } finally {
            onUpdate();
        }
    };

    const onUpdate = async () => {
        try {
            setLoading(true);
            const { data } = await list(state);
            const { values, total } = data;
            setTotal(total);
            dispatch({
                type: 'update',
                payload: values
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onUpdate();
    }, [state]);

    return (
        <div className={cn()}>
            <Space className={cn('instruments')}>
                <Button onClick={() => push('/users/add')} type="primary" icon={<UserAddOutlined />}> Добавить пользователя </Button>
            </Space>
            <div className={cn('content')}>
                <Table
                    getPopupContainer={(trigger) => trigger.parentElement}
                    key="id"
                    onRow={({ id }) => ({
                        onDoubleClick: e => push(`/users/${id}`)
                    })}
                    onChange={(pages, filters, sort) => {
                        setState({
                            pages,
                            filters,
                            sort
                        });
                    }}
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        total
                    }}
                    dataSource={data} />
            </div>
        </div>
    );
};