import bem from 'easy-bem';
import query from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Breadcrumb,
    Dropdown,
    Menu,
    Space,
    Table
} from 'antd';
import { useHistory, useParams } from 'react-router';
import { PREDICTION_STATUS_LOC, PREDICTION_STATUS } from 'models/interval-analysis/constants';
import { UnorderedListOutlined, DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { get, predictions, downloadResultsUrl } from '../../../../models/interval-analysis/api';
import { filter2server } from '../../../../models/utils';
import options from '../../../../options';
import { BREADCRUMBS } from '../../../../models/interval-analysis/constants';

const cn = bem('interval-analysis-predictions');

export default () => {
    const history = useHistory();
    const { push } = useHistory();

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        current: 1,
        pageSize: 10,
        ...query.parse(history.location.search)
    });

    const { id } = useParams();

    useEffect(() => {
        getName();

        const interval = setInterval(() => {
            onUpdate(false);
        }, options.processingUpdateInterval);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        onUpdate();
    }, [total, state]);

    const getName = async () => {
        setLoading(true);
        const { data } = await get(id);
        setName(data.name);
        setLoading(false);
    };

    const onUpdate = async (showLoader = true) => {
        try {
            setLoading(showLoader);
            const { data } = await predictions(id);
            const { values, total } = data;
            setData(values);
            setTotal(total);
        } finally {
            setLoading(false);
        }
    };

    const transformData = (item, idx) => ({
        key: idx,
        name,
        date: item.prediction_date,
        status: item.status,
        control: item.status
    });

    const onPageChange = (filter) => {
        const filterBody = filter2server(filter);
        setState(filterBody);
        history.replace(`?${query.stringify(filterBody)}`);
    };

    const AVAILABLE_ACTIONS = {
        download: [
            PREDICTION_STATUS.DONE
        ],
        openPrimaryAnalyses: [
            PREDICTION_STATUS.DONE,
            PREDICTION_STATUS.STARTED,
            PREDICTION_STATUS.FAILED
        ]
    };

    const isActionAvailable = (action, status) => AVAILABLE_ACTIONS[action]?.includes(status);

    const downloadFile = (id, predictionDate) => {
        window.open(downloadResultsUrl(id, predictionDate));
    };

    const breadcrumbs = Object.values(BREADCRUMBS).filter((_, idx) => idx !== 2);

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true
        },
        {
            title: 'Дата анализа',
            dataIndex: 'date',
            key: 'date',
            width: 170,
            render: (text) => moment(text).format(options.dateFormat)
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 130,
            render: (text) => PREDICTION_STATUS_LOC[text]
        },
        {
            title: 'Управление',
            dataIndex: 'control',
            key: 'control',
            align: 'right',
            width: 176,
            render: (text, row) => (
                <Space className={cn('actions')}>
                    {isActionAvailable('download', text) && (
                        <div>
                            <Dropdown overlay={(
                                <Menu>
                                    <Menu.Item key={row.name} onClick={() => downloadFile(id, row.date)}>
                                        shp (zip)
                                    </Menu.Item>
                                </Menu>
                            )}>
                                <DownloadOutlined
                                    title="Скачать" />
                            </Dropdown>
                        </div>
                    )}
                    {isActionAvailable('openPrimaryAnalyses', text) && (
                        <UnorderedListOutlined
                            title="Список первичных анализов"
                            onClick={() => { push(`/interval-analysis/${id}/analyzes/${row.date}`); }} />
                    )}
                </Space>
            )
        }
    ];
    return (
        <div className={cn()}>
            <Space className={cn('breadcrumbs')}>
                <ArrowLeftOutlined className={cn('breadcrumb-icon')} onClick={() => history.goBack()} />
                <Breadcrumb>
                    {
                        breadcrumbs.map((item, idx) => {
                            if (idx === breadcrumbs.length - 1) {
                                return (<Breadcrumb.Item key={item.name}>{ item.name }</Breadcrumb.Item>);
                            } else {
                                return (<Breadcrumb.Item key={item.name}><Link to={item.getUrl(useParams())}>{ item.name }</Link></Breadcrumb.Item>);
                            }
                        })
                    }
                </Breadcrumb>
            </Space>
            <div className={cn('content')}>
                <Table
                    key="id"
                    rowClassName={cn('row')}
                    loading={isLoading}
                    onChange={(pages) => {
                        onPageChange({
                            pages
                        });
                    }}
                    pagination={{
                        total,
                        current: parseInt(state.current, 10)
                    }}
                    columns={columns}
                    dataSource={data.map(transformData)} />
                    
            </div>
        </div>
    );
};
