import React from 'react';
import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Switch,
    Select,
    Slider,
    Space,
    Spin,
    Checkbox,
    Typography,
    Upload
} from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import bem from 'easy-bem';
import moment from 'moment';

import options from 'options';
import { createAuto, projections } from 'models/workers/api';
import AsyncSelect from 'containers/async-select';
import { normalizeFile } from 'utils/normalize';
import { FILTERS } from '../../constants';
import { inputValidator, isSentinelOnly, periodValidator } from '../../methods';
import { zeroDate } from '../../../../models/utils';
import { useRangePickerSwitcher } from '../../../../utils/hooks/useRangePickerSwitcher';

const cn = bem('workers-add');

const defaultValues = {
    name: `Обработчик ${moment()
        .format(options.dateTimeFormat)}`,
    max_cloud_cover: 70
};

const { TextArea } = Input;

const dateFormat = 'YYYY-MM-DD';
const yearFormat = 'YYYY';

const defaultFilterSettings = {
    landsat: {
        B6: 0.02,
        B7: 0.01,
        B9: 0.005,
        F1: 0.25,
        F2: 0.18,
        F3: 0.01
    },
    sentinel: {
        F1: 0.1,
        F2: 0.25,
        F3: 0.01,
        B11: 0.03,
        B12: 0.02,
        B10: 0.0025
    }
};

export default () => {
    const { push } = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);

    const onCancel = () => {
        push('/workers');
    };

    const onReset = () => {
        form.resetFields();
    };

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const {
                reference_date,
                target_date,
                satellite,
                analysis_year,
                ...params
            } = values;

            const body = {
                ...defaultValues,
                ...params
            };

            if (satellite.length > 1) {
                body.satellite = 'all';
            } else {
                const [item] = satellite;
                body.satellite = item;
            }

            if (reference_date) {
                const [reference_from_date, reference_to_date] = reference_date;
                body.reference_from_date = reference_from_date.format(dateFormat);
                body.reference_to_date = reference_to_date.format(dateFormat);
            }
            if (target_date) {
                const [target_from_date, target_to_date] = target_date;
                body.target_from_date = target_from_date.format(dateFormat);
                body.target_to_date = target_to_date.format(dateFormat);
            } else if (analysis_year) {
                if (body.processing_type === 'arable_cert') {
                    body.target_to_date = analysis_year.format(dateFormat);
                    body.target_from_date = analysis_year.add(-1, 'years').format(dateFormat);
                } else {
                    body.target_from_date = analysis_year.format(dateFormat);
                    body.target_to_date = body.target_from_date;
                }
            }

            if (body.area_of_interest) {
                body.area_of_interest = body?.area_of_interest[0];
            } else {
                delete body.area_of_interest;
            }

            if (body.use_base_coverage) {
                delete body.reference_date;
            } else {
                delete body.use_base_coverage;
            }

            if (body.filters) {
                body.cloud_filters = JSON.stringify(body.filters);
            }

            delete body.filters;

            const result = await createAuto(body);
            push('/workers');
        } finally {
            setLoading(false);
        }
    };

    const [
        targetDateRef,
        targetDatePressTab
    ] = useRangePickerSwitcher();

    const [
        referenceDateRef,
        referenceDatePressTab
    ] = useRangePickerSwitcher();

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    Добавление автоматического обработчика
                </Typography.Title>
                <Form
                    initialValues={defaultValues}
                    form={form}
                    layout="vertical"
                    name="auto-worker"
                    onFinish={onSubmit}
                    onFieldsChange={(changedFields, values) => {
                        const element = changedFields[0];
                        if (element) {
                            const { name, value } = changedFields[0];
                            const [first] = name;
                            switch (first) {
                                case 'processing_type': {
                                    form.setFieldsValue({ model_type: undefined });
                                    if (isSentinelOnly(value)) {
                                        form.setFieldsValue({ satellite: ['sentinel'] });
                                    }
                                    if (['multi_forest'].includes(value)) {
                                        form.setFieldsValue({ satellite: ['sentinel1'] });
                                    }
                                    break;
                                }
                                case 'satellite': {
                                    form.setFieldsValue({ disable_cloud_filters: true });
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        }
                    }}
                    scrollToFirstError>
                    <Divider> Основные настройки </Divider>
                    <div className={cn('block')}>
                        <Form.Item
                            name="name"
                            label="Название обработчика"
                            rules={[{
                                required: true,
                                message: 'Поле не задано',
                                type: 'string',
                                validator: inputValidator
                            }]}>
                            <TextArea style={{ resize: 'none' }} showCount maxLength={100} />
                        </Form.Item>
                        <Form.Item name="processing_type"
                            label="Тип обработчика"
                            rules={[{ required: true, message: 'Поле не задано' }]}>
                            <Select getPopupContainer={(trigger) => trigger.parentElement}>
                                <Select.Option value="quarry"> Карьеры </Select.Option>
                                <Select.Option value="fire"> Гари </Select.Option>
                                <Select.Option value="pathology"> Лесопатологии </Select.Option>
                                <Select.Option value="windfall"> Ветровалы </Select.Option>
                                <Select.Option value="deforestation"> Вырубки </Select.Option>
                                <Select.Option value="landfill"> ТКО </Select.Option>
                                <Select.Option value="arable"> Границы пашни </Select.Option>
                                <Select.Option value="forest"> Границы зарастания сельхоз полей </Select.Option>
                                <Select.Option value="hogweed"> Зарастание борщевиком Сосновского </Select.Option>
                                <Select.Option value="one_img_defor"> Вырубки по снимкам высокого пространственного разрешения </Select.Option>
                                <Select.Option value="arable_cert"> Границы полей с конкретными типами культур </Select.Option>
                                <Select.Option value="reforestation"> Границы лесовосстановления </Select.Option>
                                <Select.Option value="multi_forest"> Мультиклассовая сегментация: границы леса, границы используемой пашни </Select.Option>
                                <Select.Option value="pathology_v2"> Границы усыхания лесной растительности </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue, setFieldsValue }) => {
                                const type = getFieldValue('processing_type');
                                const isSummerOnly = ['arable', 'forest', 'hogweed', 'one_img_defor'].includes(type);
                                return (
                                    (
                                        <Form.Item name="model_type"
                                            label="Тип модели распознавания">
                                            <Select
                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                allowClear>
                                                <Select.Option value="summer"> Лето </Select.Option>
                                                {
                                                    !isSummerOnly && [
                                                        <Select.Option value="autumn"> Осень </Select.Option>,
                                                        <Select.Option value="winter"> Зима </Select.Option>,
                                                        <Select.Option value="spring"> Весна </Select.Option>
                                                    ]
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                );
                            }}
                        </Form.Item>
                        <Form.Item shouldUpdate={
                            (prevValues, values) => prevValues.processing_type !== values.processing_type
                        }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('processing_type');
                                const currentYear = (new Date()).getFullYear();

                                if (['arable_cert', 'reforestation', 'multi_forest'].includes(type)) {
                                    return (
                                        <Form.Item name="analysis_year"
                                            label="Год анализа"
                                            rules={[{ required: true, message: 'Поле не задано' }]}
                                            initialValue={moment(String(currentYear))}
                                            format={yearFormat}>
                                            <DatePicker
                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                picker="year"
                                                disabledDate={d => !d || d.isBefore(zeroDate)} />
                                        </Form.Item>
                                    );
                                }

                                return (
                                    <div>
                                        <div ref={targetDateRef}>
                                            <Form.Item name="target_date"
                                                label="Дата фиксации"
                                                rules={[{ required: true, message: 'Поле не задано' }]}>
                                                <DatePicker.RangePicker
                                                    getPopupContainer={(trigger) => trigger.parentElement}
                                                    format={options.dateFormat}
                                                    disabledDate={d => !d || d.isBefore(zeroDate)}
                                                    placeholder={['от', 'до']}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Tab') targetDatePressTab();
                                                    }} />
                                            </Form.Item>
                                        </div>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={
                                                (prevValues, values) =>
                                                    prevValues.processing_type !== values.processing_type || prevValues.use_base_coverage !== values.use_base_coverage
                                            }>
                                            {({ getFieldValue }) => {
                                                const types = ['windfall', 'deforestation'];
                                                const type = getFieldValue('processing_type');
                                                const isBaseCoverage = getFieldValue('use_base_coverage');
                                                return (
                                                    <div ref={referenceDateRef}>
                                                        <Form.Item name="reference_date"
                                                            label="Контрольная дата"
                                                            rules={[{
                                                                required: types.includes(type) && !isBaseCoverage,
                                                                message: 'Поле не задано'
                                                            }]}>
                                                            <DatePicker.RangePicker
                                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                                disabled={!types.includes(type) || isBaseCoverage}
                                                                disabledDate={d => !d || d.isBefore(zeroDate)}
                                                                format={options.dateFormat}
                                                                placeholder={['от', 'до']}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Tab') referenceDatePressTab();
                                                                }} />
                                                        </Form.Item>
                                                    </div>
                                                );
                                            }}
                                        </Form.Item>
                                    </div>
                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const types = ['windfall', 'deforestation'];
                                const type = getFieldValue('processing_type');

                                if (['arable_cert', 'reforestation', 'multi_forest'].includes(type)) return null;
                                return (
                                    <Form.Item
                                        valuePropName="checked"
                                        name="use_base_coverage"
                                        label="Контрольный снимок из базового покрытия">
                                        <Switch disabled={!types.includes(type)} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('processing_type');
                                if (['multi_forest'].includes(type)) return null;
                                return (
                                    <Form.Item name="max_cloud_cover"
                                        label="Процент облачности"
                                        rules={[{
                                            required: true,
                                            type: 'number',
                                            max: 100,
                                            min: 0
                                        }]}>
                                        <Slider min={0} max={100} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            name="area_of_interest"
                            label="Область анализа"
                            rules={[{ required: true, message: 'Пожалуйста, загрузите данные для анализа' }]}
                            valuePropName="fileList"
                            getValueFromEvent={normalizeFile}>
                            <Upload
                                accept=".zip"
                                name="area_of_interest"
                                beforeUpload={() => false}
                                listType="text">
                                <Button type="primary">
                                    <UploadOutlined /> Загрузить файл
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('processing_type');

                                return (
                                    <Form.Item
                                        name="satellite"
                                        label="Тип"
                                        rules={[{
                                            required: true
                                        }]}>
                                        <Checkbox.Group>
                                            {
                                                !['one_img_defor', 'multi_forest'].includes(type) && [
                                                    <Checkbox disabled={isSentinelOnly(type)} value="sentinel">Тип 1</Checkbox>,
                                                    !isSentinelOnly(type) && <Checkbox value="landsat">Тип 2</Checkbox>
                                                ]
                                            }
                                            {
                                                type === 'one_img_defor' && (
                                                    [
                                                        <Checkbox value="planet">Planet</Checkbox>,
                                                        <Checkbox value="kanopus">Канопус-В</Checkbox>
                                                    ]
                                                )
                                            }
                                            {
                                                type === 'multi_forest' && (
                                                    [
                                                        <Checkbox disabled value="sentinel1">sentinel 1</Checkbox>
                                                    ]
                                                )
                                            }
                                        </Checkbox.Group>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('processing_type');

                                if (type === 'one_img_defor') {
                                    return null;
                                }

                                return (
                                    <Form.Item
                                        name="projection_id"
                                        label="Привести к проекции">
                                        <AsyncSelect source={projections} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.disable_cloud_filters !== values.disable_cloud_filters
                                    || prevValues.satellite !== values.satellite
                            }>
                            {({ getFieldValue, setFieldsValue }) => {
                                const satellite = getFieldValue('satellite') ?? [];
                                const processing_type = getFieldValue('processing_type');
                                const isActive = satellite.includes('planet') || satellite.includes('kanopus');
                                if (isActive || ['arable_cert', 'multi_forest'].includes(processing_type)) {
                                    return null;
                                }
                                return (
                                    <Form.Item
                                        valuePropName="checked"
                                        name="disable_cloud_filters"
                                        label="Отключить фильтрацию">
                                        <Switch disabled={isActive} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    </div>

                    <Divider> Дополнительные параметры </Divider>

                    <Form.Item
                        noStyle
                        shouldUpdate={
                            (prevValues, values) => prevValues.disable_cloud_filters !== values.disable_cloud_filters
                                || prevValues.satellite !== values.satellite
                        }>
                        {({ getFieldValue }) => {
                            const disableCloudFilters = getFieldValue('disable_cloud_filters');
                            const satellite = getFieldValue('satellite') ?? [];
                            const isActive = satellite.includes('planet') || satellite.includes('kanopus');
                            if (disableCloudFilters || isActive) return false;
                            return (
                                <Collapse ghost>
                                    <Collapse.Panel header="Параметры фильтрации снимков" key="1">
                                        <div className={cn('block')}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={
                                                    (prevValues, values) =>
                                                        prevValues.satellite !== values.satellite
                                                }>
                                                {({ getFieldValue, setFieldsValue }) => {
                                                    const data = getFieldValue(['filters']) ?? {};
                                                    const types = getFieldValue(['satellite']);
                                                    if (!types || types?.length === 0) return null;

                                                    setFieldsValue({
                                                        filters: { ...data, ...defaultFilterSettings }
                                                    });
                                                    return (
                                                        <Row gutter={[16]} className={cn('group')}>
                                                            {
                                                                types.map((type) => (
                                                                    <Col>
                                                                        <Typography.Title level={4}> {type} </Typography.Title>
                                                                        <Row gutter={[8, 8]}>
                                                                            {
                                                                                FILTERS[type].map(({ label, value }) => (
                                                                                    <Col>
                                                                                        <Form.Item
                                                                                            name={['filters', type, value]}
                                                                                            key={value}
                                                                                            label={label}
                                                                                            rules={[{
                                                                                                required: true,
                                                                                                type: 'number',
                                                                                                max: 5,
                                                                                                min: -1
                                                                                            }]}>
                                                                                            <InputNumber
                                                                                                min={-1}
                                                                                                max={5}
                                                                                                step={0.001} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                    );
                                                }}
                                            </Form.Item>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            );
                        }}
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Создать
                            </Button>
                            <Button onClick={onCancel}>
                                Отмена
                            </Button>

                            <Button danger type="link" onClick={onReset}>
                                Сбросить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};
