import React, { Component, Suspense } from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router';

import Loader from 'components/suspense-loader';

import Menu from 'components/menu';
import Header from 'containers/header';
import Body from 'components/body';
import Content from 'components/content';

import List from './forms/list';
import Add from './forms/add';
import Edit from './forms/edit';

import './style.less';

export default () => (
    <Layout>
        <Menu />
        <Body>
            <Header />
            <Content>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route
                            exact
                            key="/users/add"
                            path="/users/add"
                            component={Add} />
                        <Route
                            exact
                            key="/users/:id"
                            path="/users/:id"
                            component={Edit} />
                        <Route
                            exact
                            key="/users"
                            path="/users"
                            component={List} />
                        <Redirect to="/users" />
                    </Switch>
                </Suspense>
            </Content>
        </Body>
    </Layout>
);