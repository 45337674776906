import React from 'react';
import {
    Button, Checkbox,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Space,
    Spin, Switch,
    Typography,
    Upload
} from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import bem from 'easy-bem';

import { change, projections } from 'models/workers/api';
import AsyncSelect from 'containers/async-select';
import { normalizeFile } from 'utils/normalize';

import { EDIT_STATUS_LIST, FILTERS } from '../../constants';
import { inputValidator } from '../../methods';

const cn = bem('workers-add');

const { TextArea } = Input;

export default ({ item }) => {
    console.log('test manuals', item, FILTERS);

    const { push } = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);
    const isDisabled = !EDIT_STATUS_LIST.includes(item.status.status);

    const onCancel = () => {
        push('/workers');
    };

    const onReset = () => {
        form.resetFields();
    };

    const normalizeValues = React.useMemo(() => {
        const { satellite } = item;

        return {
            ...item,
            satellite: satellite === 'all' ? ['landsat', 'sentinel'] : [satellite]
        };
    }, [item]);

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const body = {
                ...values
            };

            if (body.satellite.length > 1) {
                body.satellite = 'all';
            } else {
                const [item] = body.satellite;
                body.satellite = item;
            }

            if (body.area_of_interest) {
                body.area_of_interest = body?.area_of_interest[0];
            } else {
                delete body.area_of_interest;
            }

            if (body.filters) {
                body.cloud_filters = JSON.stringify(body.filters);
            }
            delete body.filters;

            const content = await change(item.id, body);
            item.current_area_of_interest = content.data.area_of_interest;
        } finally {
            setLoading(false);
            push('/workers');
        }
    };

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    Ручной обработчик {item.id}
                </Typography.Title>
                <Form
                    initialValues={normalizeValues}
                    form={form}
                    layout="vertical"
                    name="manual-worker"
                    onFinish={onSubmit}
                    onFieldsChange={(changedFields, values) => {
                        const element = changedFields[0];
                        if (element) {
                            const { name, value } = changedFields[0];
                            const [first] = name;
                            switch (first) {
                                case 'processing_type': {
                                    form.setFieldsValue({ model_type: undefined });
                                    break;
                                }
                                case 'satellite': {
                                    form.setFieldsValue({ disable_cloud_filters: true });
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        }
                    }}
                    scrollToFirstError>
                    <Divider> Основные настройки </Divider>
                    <div className={cn('block')}>
                        <Form.Item
                            name="name"
                            label="Название обработчика"
                            rules={[{
                                required: true,
                                message: 'Поле не задано',
                                type: 'string',
                                validator: isDisabled ? undefined : inputValidator
                            }]}>
                            <TextArea style={{ resize: 'none' }} showCount maxLength={100} disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item name="processing_type"
                            label="Тип обработчика"
                            rules={[{ required: true, message: 'Поле не задано' }]}>
                            <Select
                                getPopupContainer={(trigger) => trigger.parentElement}
                                disabled={isDisabled}>
                                <Select.Option value="quarry"> Карьеры </Select.Option>
                                <Select.Option value="fire"> Гари </Select.Option>
                                <Select.Option
                                    value="pathology"> Лесопатологии
                                </Select.Option>
                                <Select.Option value="windfall"> Ветровалы </Select.Option>
                                <Select.Option value="deforestation"> Вырубки </Select.Option>
                                <Select.Option value="landfill"> ТКО </Select.Option>
                                <Select.Option value="arable"> Границы пашни </Select.Option>
                                <Select.Option value="forest"> Границы зарастания сельхоз полей </Select.Option>
                                <Select.Option value="hogweed"> Зарастание борщевиком Сосновского </Select.Option>
                                <Select.Option value="one_img_defor"> Вырубки по снимкам высокого пространственного разрешения </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.processing_type !== values.processing_type || prevValues.use_base_coverage !== values.use_base_coverage
                            }>
                            {({ getFieldValue, setFieldsValue }) => {
                                const type = getFieldValue('processing_type');
                                const isSummerOnly = ['arable', 'forest', 'hogweed', 'one_img_defor'].includes(type);
                                return (
                                    (
                                        <Form.Item name="model_type"
                                            label="Тип модели распознавания">
                                            <Select
                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                disabled={isDisabled}
                                                allowClear>
                                                <Select.Option value="summer"> Лето </Select.Option>
                                                {
                                                    !isSummerOnly && [
                                                        <Select.Option value="autumn"> Осень </Select.Option>,
                                                        <Select.Option value="winter"> Зима </Select.Option>,
                                                        <Select.Option value="spring"> Весна </Select.Option>
                                                    ]
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                );
                            }}
                        </Form.Item>

                        <Form.Item
                            name="target_image"
                            label="Id анализируемого снимка"
                            rules={[{
                                required: true,
                                message: 'Поле не задано',
                                type: 'string',
                                validator: isDisabled ? undefined : inputValidator
                            }]}>
                            <Input disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const types = ['windfall', 'deforestation'];
                                const type = getFieldValue('processing_type');
                                return (
                                    <Form.Item
                                        name="reference_image"
                                        label="Id контрольного снимка"
                                        rules={[{
                                            required: types.includes(type),
                                            type: 'string',
                                            validator: (!types.includes(type) || isDisabled) ? undefined : inputValidator,
                                            message: 'Поле не задано'
                                        }]}>
                                        <Input disabled={!types.includes(type) || isDisabled} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) =>
                                    prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const types = ['pathology'];
                                const type = getFieldValue('processing_type');
                                const isShow = !types.includes(type);
                                return isShow && (
                                    <Form.Item
                                        name="area_of_interest"
                                        label="Область анализа"
                                        valuePropName="fileList"
                                        getValueFromEvent={normalizeFile}>
                                        <Upload
                                            accept=".zip"
                                            name="area_of_interest"
                                            beforeUpload={() => false}
                                            listType="text">
                                            <Button disabled={isDisabled} type="primary">
                                                <UploadOutlined /> Загрузить файл
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                        <Form.Item>
                            <h5> {item.current_area_of_interest} </h5>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue, setFieldsValue }) => {
                                const processingType = getFieldValue('processing_type');
                                const type = getFieldValue('processing_type');
                                const isSentinelOnly = ['arable', 'forest', 'hogweed'].includes(type);
                                return (
                                    <Form.Item
                                        name="satellite"
                                        label="Тип"
                                        rules={[{
                                            required: true
                                        }]}>
                                        <Checkbox.Group>
                                            {
                                                processingType !== 'one_img_defor' && [
                                                    <Checkbox value="sentinel" disabled={isDisabled}>sentinel 2</Checkbox>,
                                                    !isSentinelOnly && <Checkbox value="landsat" disabled={isDisabled}>landsat</Checkbox>
                                                ]
                                            }
                                            {
                                                processingType === 'one_img_defor' && (
                                                    [
                                                        <Checkbox value="planet">Planet</Checkbox>,
                                                        <Checkbox value="kanopus">Канопус-В</Checkbox>
                                                    ]
                                                )
                                            }
                                        </Checkbox.Group>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.processing_type !== values.processing_type
                            }>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('processing_type');

                                if (type === 'one_img_defor') {
                                    return null;
                                }

                                return (
                                    <Form.Item
                                        name="projection_id"
                                        label="Привести к проекции">
                                        <AsyncSelect source={projections} disabled={isDisabled} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={
                                (prevValues, values) => prevValues.disable_cloud_filters !== values.disable_cloud_filters
                                    || prevValues.satellite !== values.satellite
                            }>
                            {({ getFieldValue, setFieldsValue }) => {
                                const satellite = getFieldValue('satellite') ?? [];
                                const isActive = satellite.includes('planet') || satellite.includes('kanopus');
                                if (isActive) {
                                    return null;
                                }
                                return (
                                    <Form.Item
                                        valuePropName="checked"
                                        name="disable_cloud_filters"
                                        label="Отключить фильтрацию">
                                        <Switch disabled={isActive || isDisabled} />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    </div>

                    <Divider> Дополнительные параметры </Divider>
                    <Form.Item
                        noStyle
                        shouldUpdate={
                            (prevValues, values) => prevValues.disable_cloud_filters !== values.disable_cloud_filters
                                || prevValues.satellite !== values.satellite
                        }>
                        {({ getFieldValue }) => {
                            const disableCloudFilters = getFieldValue('disable_cloud_filters');
                            const satellite = getFieldValue('satellite') ?? [];
                            const isActive = satellite.includes('planet') || satellite.includes('kanopus');
                            if (disableCloudFilters || isActive) return false;
                            return (
                                <Collapse ghost>
                                    <Collapse.Panel header="Параметры фильтрации снимков" key="1">
                                        <div className={cn('block')}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={
                                                    (prevValues, values) =>
                                                        prevValues.satellite !== values.satellite
                                                }>
                                                {({ getFieldValue, setFieldsValue }) => {
                                                    const data = getFieldValue(['filters']) ?? {};
                                                    const types = getFieldValue(['satellite']);
                                                    if (!types || types?.length === 0) return null;

                                                    setFieldsValue({
                                                        filters: { ...data }
                                                    });
                                                    return (
                                                        <Row gutter={[16]} className={cn('group')}>
                                                            {
                                                                types.map((type) => (
                                                                    <Col>
                                                                        <Typography.Title level={4}> {type} </Typography.Title>
                                                                        <Row gutter={[8, 8]}>
                                                                            {
                                                                                FILTERS[type].map(({ label, value }) => (
                                                                                    <Col>
                                                                                        <Form.Item
                                                                                            name={['filters', type, value]}
                                                                                            key={value}
                                                                                            label={label}
                                                                                            rules={[{
                                                                                                required: true,
                                                                                                type: 'number',
                                                                                                max: 5,
                                                                                                min: -1
                                                                                            }]}>
                                                                                            <InputNumber
                                                                                                disabled={isDisabled}
                                                                                                min={-1}
                                                                                                max={5}
                                                                                                step={0.001} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                    );
                                                }}
                                            </Form.Item>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            );
                        }}
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={isDisabled}>
                                Редактировать
                            </Button>
                            <Button onClick={onCancel}>
                                Отмена
                            </Button>
                            <Button danger type="link" onClick={onReset}>
                                Сбросить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};
