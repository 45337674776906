import React, { useEffect, useState, useReducer } from 'react';
import query from 'query-string';
import bem from 'easy-bem';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Button, Space, Popconfirm, Dropdown, Menu
} from 'antd';
import {
    PlusCircleOutlined,
    PlayCircleOutlined,
    PauseOutlined,
    LoadingOutlined,
    DeleteOutlined,
    CopyOutlined,
    RedoOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import options from 'options';

import {
    list,
    start,
    remove,
    copy,
    stop
} from 'models/interval-analysis/api';

import {
    STATUS_LOC,
    TYPES,
    STATUS,
    TYPES_LOC
} from 'models/interval-analysis/constants';

import { filter2server } from 'models/utils';

import Table from 'components/table';

const cn = bem('analysis-list');

const reducer = (state, action) => {
    switch (action.type) {
        case 'status-change': {
            const { id, status } = action.payload;
            return state.map((item) => {
                if (item.id === id) {
                    item.status = status;
                }
                return item;
            });
        }

        case 'update': {
            return action.payload;
        }

        default: {
            throw new Error('No such reduce action');
        }
    }
};

const AVAILABLE_ACTIONS = {
    start: [
        STATUS.CREATED,
        STATUS.STOPPED
    ],
    stop: [
        STATUS.STARTED
    ],
    duplicate: [
        STATUS.CREATED,
        STATUS.STARTED,
        STATUS.DONE,
        STATUS.STOPPED
    ],
    loading: [
        STATUS.LOADING
    ],
    remove: [
        STATUS.CREATED,
        STATUS.STARTED,
        STATUS.DONE,
        STATUS.STOPPED
    ],
    openPredictions: [
        STATUS.STARTED,
        STATUS.DONE,
        STATUS.STOPPED
    ]
};

const isActionAvailable = (action, status) =>
  AVAILABLE_ACTIONS[action]?.includes(status);

export default () => {
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();

    const [data, dispatch] = useReducer(reducer, []);
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        current: 1,
        pageSize: 10,
        ...query.parse(history.location.search)
    });

    const onChangeFilter = (filter) => {
        const filterBody = filter2server(filter);
        setState(filterBody);
        history.replace(`?${query.stringify(filterBody)}`);
    };

    const { push } = useHistory();

    useEffect(() => {
        const updater = setInterval(
            () => onUpdate(false),
            options.processingUpdateInterval
        );
        return () => {
            clearInterval(updater);
        };
    }, [state, total]);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: 'string',
            render: (name, row) => <Link to={`/interval-analysis/${row.id}`}> {name} </Link>,
            ellipsis: true
        },
        {
            title: 'Тип',
            dataIndex: 'analysis_type',
            width: 300,
            key: 'analysis_type',
            render: (text) => {
                switch (text) {
                    case 'fire_analysis':
                        return 'Вероятность возникновения пожара';
                    default:
                        return 'Вероятность возникновения пожара';
                }
            }
            // sorter: true,
            // filters: [
            //     { text: TYPES_LOC[TYPES.FIRE], value: TYPES.FIRE },
            // ]
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (text) => STATUS_LOC[text],
            sorter: true,
            filters: [
                { text: STATUS_LOC[STATUS.STARTED], value: STATUS.STARTED },
                { text: STATUS_LOC[STATUS.CREATED], value: STATUS.CREATED },
                { text: STATUS_LOC[STATUS.DONE], value: STATUS.DONE },
                { text: STATUS_LOC[STATUS.STOPPED], value: STATUS.STOPPED }
            ],
            width: 130
        },
        {
            title: 'Начало периода',
            dataIndex: 'from_date',
            key: 'from_date',
            render: (text) => moment(text).format(options.dateFormat),
            sorter: true,
            filters: 'date-range',
            width: 132
        },
        {
            title: 'Конец периода',
            dataIndex: 'to_date',
            key: 'to_date',
            render: (text) => moment(text).format(options.dateFormat),
            sorter: true,
            filters: 'date-range',
            width: 132
        },
        {
            title: 'Дата и время создания',
            dataIndex: 'created',
            key: 'created',
            render: (text) => moment(text).format(options.dateTimeFormat),
            sorter: true,
            filters: 'date-range',
            width: 170

        },
        {
            title: () => (
                <Button type="link" shape="circle" onClick={onUpdate}>
                    {' '}
                    <RedoOutlined />{' '}
                </Button>
            ),
            dataIndex: ['status'],
            key: 'control',
            align: 'right',
            width: 176,
            render: (text, row) => (
                <Space className={cn('actions')}>
                    { isActionAvailable('stop', text) && <PauseOutlined title="Остановить" onClick={onStop(row.id)} /> }
                    {isActionAvailable('start', text) && (
                        <PlayCircleOutlined title="Запустить" onClick={onStart(row.id)} />
                    )}
                    {isActionAvailable('openPredictions', text) && (
                        <UnorderedListOutlined
                            title="Список дат анализов"
                            onClick={() => push(`/interval-analysis/${row.id}/current_predictions`)} />
                    )}
                    {isActionAvailable('remove', text) && (
                        <Popconfirm
                            title="Удалить периодический пространственный анализ?"
                            onConfirm={onRemove(row.id)}
                            okText="Да"
                            cancelText="Нет">
                            <DeleteOutlined
                                onClick={(e) => e.stopPropagation()}
                                title="Удалить" />
                        </Popconfirm>
                    )}
                    {isActionAvailable('loading', text) && (
                        <LoadingOutlined style={{ fontSize: 24 }} spin primary />
                    )}
                    {isActionAvailable('duplicate', text) && (
                        <CopyOutlined
                            title="Создать дубликат"
                            onClick={onDuplicate(row.id)} />
                    )}
                </Space>
            )
        }
    ];

    const onStatusChange = (id, status) => {
        dispatch({
            type: 'status-change',
            payload: {
                id,
                status
            }
        });
    };

    const onRemove = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await remove(id);
        } finally {
            onUpdate();
        }
    };

    const onStart = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await start(id);
        } finally {
            onUpdate();
        }
    };

    const onStop = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await stop(id);
        } finally {
            onUpdate();
        }
    };

    const onDuplicate = (id) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await copy(id);
        } finally {
            onUpdate();
        }
    };

    const onUpdate = async (showLoader = true) => {
        try {
            setLoading(showLoader);
            const { data } = await list(state);
            const { values, total } = data;
            setTotal(total);
            dispatch({
                type: 'update',
                payload: values
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onUpdate();
    }, [state]);

    return (
        <div className={cn()}>
            <Space className={cn('instruments')}>
                <Button
                    onClick={() => push('/interval-analysis/add')}
                    type="primary"
                    icon={<PlusCircleOutlined />}>
                    {' '}
          Добавить периодический пространственный анализ{' '}
                </Button>
            </Space>
            <div className={cn('content')}>
                <Table
                    getPopupContainer={(trigger) => trigger.parentElement}
                    key="id"
                    rowClassName={cn('row')}
                    onChange={(pages, filters, sort) => {
                        onChangeFilter({
                            pages,
                            filters,
                            sort
                        });
                    }}
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        total,
                        current: Number(state.current)
                    }}
                    dataSource={data.map((item, idx) => ({
                        key: idx,
                        ...item
                    }))} />
            </div>
        </div>
    );
};
