import React from 'react';
import { useParams, useHistory } from 'react-router';
import { Spin } from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import bem from 'easy-bem';

import { get } from 'models/workers/api';
import { TYPES } from 'models/workers/constants';

import Auto from './auto';
import Manual from './manual';

const cn = bem('workers-item');

export default () => {
    const [item, setItem] = React.useState();
    const { id } = useParams();
    const { push } = useHistory();

    const init = async () => {
        try {
            const { data } = await get(id);
            const item = { ...data };

            if (item?.cloud_filters) {
                item.filters = item.cloud_filters;
            }
            delete item.cloud_filters;

            item.current_area_of_interest = item.area_of_interest;
            delete item.area_of_interest;

            if (item.target_from_date) {
                if (['arable_cert', 'reforestation', 'multi_forest'].includes(item.processing_type)) {
                    item.analysis_year = moment(item.target_to_date);
                } else {
                    item.target_date = [
                        moment(item.target_from_date),
                        moment(item.target_to_date)
                    ];
                }
            }
           
            if (item.reference_from_date) {
                item.reference_date = [
                    moment(item.reference_from_date),
                    moment(item.reference_to_date)
                ];
            }
            if (item.period_from_date) {
                item.period_date = [
                    moment(item.period_from_date),
                    moment(item.period_to_date)
                ];
            }

            setItem(item);
        } catch (e) {
            push('/workers');
        }
    };

    React.useEffect(() => {
        init();
    }, []);

    if (item) {
        return item?.creation_type === TYPES.AUTO ? <Auto item={item} /> : <Manual item={item} />;
    }

    return (
        <Spin className={cn('loader')} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
    );
};
