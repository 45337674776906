import React from 'react';

import Private from 'containers/private';
import Component from './component';

export default <Private
    exact
    isGuestOnly
    key="auth"
    path="/auth"
    component={Component} />;
