import React from 'react';
import {
    Button, Divider, Form, Input, Space, Spin, Typography
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import bem from 'easy-bem';

import { create } from 'models/users/api';

const cn = bem('users-add');

export default () => {
    const { push } = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = React.useState(false);

    const onCancel = () => {
        push('/users');
    };

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const body = {
                ...values
            };

            delete body.confirm;

            const content = await create(body);
            onCancel();
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={cn()}>
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Typography.Title level={4}>
                    Добавление пользователя
                </Typography.Title>
                <Form
                    form={form}
                    layout="vertical"
                    name="auto-worker"
                    onFinish={onSubmit}
                    scrollToFirstError>
                    <Divider> Основные настройки </Divider>
                    <div className={cn('block')}>
                        <Form.Item
                            name="login"
                            label="Логин"
                            rules={[{
                                required: true
                            }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Пароль"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Повторите пароль"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, подтвердите пароль'
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Пароли не совпадают');
                                    }
                                })
                            ]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[{
                                required: true,
                                type: 'email'
                            }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            label="Фамилия"
                            rules={[{
                                required: true,
                                type: 'string'
                            }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="surname"
                            label="Имя"
                            rules={[{
                                required: true,
                                type: 'string'
                            }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="middle_name"
                            label="Отчество"
                            rules={[{
                                required: true,
                                type: 'string'
                            }]}>
                            <Input />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Создать
                            </Button>
                            <Button onClick={onCancel}>
                                Отмена
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};