import React, { Component, Suspense } from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router';

import Loader from 'components/suspense-loader';

import Menu from 'components/menu';
import Header from 'containers/header';
import Body from 'components/body';
import Content from 'components/content';

import AddAuto from './forms/add';
import Item from './forms/item';
import List from './forms/list';

import './style.less';

export default () => (
    <Layout>
        <Menu />
        <Body>
            <Header />
            <Content>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route
                            exact
                            key="/analysis/add"
                            path="/analysis/add"
                            component={AddAuto} />
                        <Redirect
                            exact
                            from="/analysis/add"
                            to="/analysis" />
                        <Route
                            exact
                            key="/analysis/:id"
                            path="/analysis/:id"
                            component={Item} />
                        <Route
                            exact
                            key="/analysis"
                            path="/analysis"
                            component={List} />
                        <Redirect to="/analysis" />
                    </Switch>
                </Suspense>
            </Content>
        </Body>
    </Layout>
);