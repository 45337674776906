import React from 'react';
import { Layout } from 'antd';
import bem from 'easy-bem';

import './style.less';

const cn = bem('content');

const Content = ({ children, ...props }) => (
    <Layout.Content className={cn()} {...props}>
        {children}
    </Layout.Content>
);

export default Content;