export const STATUS = {
    CREATED: 'created',
    STARTED: 'started',
    STOPPED: 'stopped',
    DONE: 'done',
    FAILURE: 'failed',
    DELETED: 'deleted',
    LOADING: 'loading',
    NO_IMAGES: 'no_images',
    UPDATE: 'update',
    NO_RESULTS: 'no_results',
    WAIT_FOR_TIME: 'wait_for_time'
};

export const STATUS_LOC = {
    [STATUS.CREATED]: 'Создан',
    [STATUS.STARTED]: 'Активен',
    [STATUS.STOPPED]: 'Остановлен',
    [STATUS.DONE]: 'Выполнен',
    [STATUS.FAILURE]: 'Ошибка',
    [STATUS.DELETED]: 'Удален',
    [STATUS.LOADING]: 'Загрузка',
    [STATUS.NO_IMAGES]: 'Снимки не найдены',
    [STATUS.UPDATE]: 'Поиск снимков',
    [STATUS.NO_RESULTS]: 'Нет результатов',
    [STATUS.WAIT_FOR_TIME]: 'Ожидаются новые снимки'
};

export const TYPES = {
    AUTO: 'auto',
    MANUAL: 'manual'
};

export const TYPES_LOC = {
    [TYPES.AUTO]: 'Автоматический',
    [TYPES.MANUAL]: 'Ручной'
};
