import React from 'react';
import { Layout } from 'antd';
import bem from 'easy-bem';

import './style.less';

const cn = bem('layout');

export default ({ children, ...props }) => (
    <Layout className={cn()} {...props}>
        {children}
    </Layout>
);