import axios from 'axios';
import { object2Multipart, getToken } from 'utils/utils';
import { getCreationType } from './methods';

/**
 * Получение списка элементов
 * @returns {Promise}
 */
export const list = (state) => axios.get('/api/analysis', { params: state });

/**
 * Получение элемента пространственного анализа
 * @returns {Promise}
 */
export const get = (id) => axios.get(`/api/analysis/${id}`);

/**
 * Получение Списка проекций
 * @returns {Promise}
 */
export const projections = () => axios.get('/api/processings/projections');

/**
 * Создание элемента
 * @param body
 * @returns {Promise}
 */
export const create = (body) => {
    const content = object2Multipart(body);
    return axios.post(`/api/analysis/create/${getCreationType(body.analysis_type)}`, content);
};

/**
 * Редактирование элемента
 * @param body
 * @returns {Promise}
 */
export const change = (id, body) => {
    const content = object2Multipart(body);
    const result = axios.patch(`/api/analysis/${id}`, content);
    return result;
};

/**
 * Запуск процессинга
 * @param id
 * @param isForce
 * @returns {Promise}
 */
export const start = async (id, isForce) => axios.post('/api/analysis/start', { id, restart: isForce });

/**
 * Создание дубликата процессинга
 * @param id
 * @returns {Promise}
 */
export const copy = async (id) => axios.post(`/api/analysis/copy/${id}`);

/** Удаление процессинга
 *
 * @param id
 * @returns {Promise}
 */
export const remove = async (id) => axios.delete(`/api/analysis/${id}`);

/**
 * Скачивание результатов (Формирование ссылки)
 * */
export const downloadResultsUrl = (key, links) => `/api/analysis/download/${links[key]}?token=${getToken()}`;
