import { STATUS } from 'models/workers/constants';

export const FILTERS = {
    landsat: [
        {
            value: 'B6',
            label: 'B6 (min)'
        },
        {
            value: 'B7',
            label: 'B7 (min)'
        },
        {
            value: 'B9',
            label: 'B9 (min)'
        },
        {
            value: 'F1',
            label: 'F1 (max)'
        },
        {
            value: 'F2',
            label: 'F2 (max)'
        },
        {
            value: 'F3',
            label: 'F3'
        }
    ],
    sentinel: [
        {
            value: 'B11',
            label: 'B11 (min)'
        },
        {
            value: 'B12',
            label: 'B12 (min)'
        },
        {
            value: 'B10',
            label: 'B10 (max)'
        },
        {
            value: 'F1',
            label: 'F1 (max)'
        },
        {
            value: 'F2',
            label: 'F2 (max)'
        },
        {
            value: 'F3',
            label: 'F3 (max)'
        }
    ]
};

export const EDIT_STATUS_LIST = [
    STATUS.CREATED,
    STATUS.FAILURE
];
