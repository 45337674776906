export const PATH = {
    USERS: '/users',
    WORKERS: '/workers',
    ANALYSIS: '/analysis',
    // ANALYSIS_ADD: '/analysis/add',
    INTERVAL_ANALYSIS: '/interval-analysis'
};

export const HEADER = {
    [PATH.USERS]: 'Пользователи',
    [PATH.WORKERS]: 'Обработчики',
    [PATH.ANALYSIS]: 'Анализ',
    // [PATH.ANALYSIS_ADD]: 'Добавить пространственный анализ',
    [PATH.INTERVAL_ANALYSIS]: 'Периодический анализ'
};