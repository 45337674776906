export const STATUS = {
    CREATED: 'created',
    STARTED: 'started',
    DONE: 'done',
    STOPPED: 'stopped'
};

export const STATUS_LOC = {
    [STATUS.CREATED]: 'Создан',
    [STATUS.STARTED]: 'Активен',
    [STATUS.DONE]: 'Выполнен',
    [STATUS.STOPPED]: 'Остановлен',
    [STATUS.LOADING]: 'Загрузка'
};

export const PREDICTION_STATUS = {
    STARTED: 'started',
    DONE: 'done',
    FAILED: 'failed'
};

export const PREDICTION_STATUS_LOC = {
    [PREDICTION_STATUS.DONE]: 'Выполнен',
    [PREDICTION_STATUS.FAILED]: 'Ошибка',
    [PREDICTION_STATUS.STARTED]: 'Активен'
};

export const TYPES = {
    ARABLE: 'arable_analysis',
    FIRE: 'fire_analysis',
    WOOD: 'wood_selection',
    TRACK: 'track_analysis'
};

export const TYPES_LOC = {
    // [TYPES.ARABLE]: 'Факт обработки пашни',
    [TYPES.FIRE]: 'Вероятность возникновения пожара'
    // [TYPES.WOOD]: 'Участки для заготовки древесины',
    // [TYPES.TRACK]: 'Транспортная доступность лесных кварталов'
};

export const CREATION_TYPES = {
    ARBITRARY: 'arbitrary',
    PROCESSING: 'processing'
};

export const BREADCRUMBS = {
    intervalAnalysis: {
        name: 'Периодический анализ',
        getUrl: (params) => '/interval-analysis'
    },
    analysesDateList: {
        name: 'Список дат анализов',
        getUrl: (params) => `/interval-analysis/${params.id}/current_predictions`
    },
    analysesPrimaryList: {
        name: 'Список первичных анализов',
        getUrl: (params) => `/interval-analysis/${params.id}/analyzes/${params.date}`
    }
};
