/* eslint-env browser */
import 'core-js/stable';

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { createBrowserHistory } from 'history';
import axios from 'axios';

import Auth from 'containers/authorization';

import routes from './routes';

import './style/style.less';

export const history = createBrowserHistory();

axios.interceptors.response.use((response) => response, (error) => {
    const { response } = error;
    const { data, status } = response;

    switch (status) {
        case 401: {
            if (!window.location.href.includes('/auth')) {
                window.location.replace('/auth');
            }
            break;
        }
        default: {
            message.error(data);
            break;
        }
    }
    throw error;
});

render(
    <ConfigProvider locale={ruRU}>
        <Router history={history}>
            <Auth>
                { routes }
            </Auth>
        </Router>
    </ConfigProvider>,
    document.getElementById('root'),
);

window.version = '1.0.0';
