import React, { useEffect, useRef, useState } from 'react';
import {
    Table, Input, Button, Space, DatePicker
} from 'antd';
import { FilterFilled } from '@ant-design/icons';
import bem from 'easy-bem';

import './style.less';
import options from 'options';
import moment from 'moment';

const cn = bem('table');

const Filter = ({
    setSelectedKeys,
    item,
    selectedKeys,
    confirm
}) => {
    const { title, filters } = item;
    switch (filters) {
        case 'date-range': {
            const rangePickerRef = useRef(null);
            let rangeInputs = null;
            useEffect(() => {
                rangeInputs = rangePickerRef.current.querySelectorAll('.ant-picker-input > input');
                if (!rangeInputs.length) return;
                rangeInputs[0].setAttribute('edge', 'start');
                rangeInputs[1].setAttribute('edge', 'end');
            });

            const pressTab = (e) => {
                const inputsWrappers = rangePickerRef.current.querySelectorAll('.ant-picker-input');
                const activeClassName = 'ant-picker-input-active';
                const startInFocus = rangeInputs[0] === document.activeElement;
                const focusIndex = startInFocus ? 1 : 0;
                const blurIndex = startInFocus ? 0 : 1;
                setFocusedInput(startInFocus ? 'end' : 'start');

                rangeInputs[blurIndex].blur();
                inputsWrappers[blurIndex].classList.remove(activeClassName);
                rangeInputs[focusIndex].focus();
                inputsWrappers[focusIndex].classList.add(activeClassName);
            };

            const pressEnter = (e) => {
                const [start, end] = Array.from(rangeInputs).map(input => {
                    const [day, month, year] = input.value
                        .split('.')
                        .map(item => Number(item));
                    return moment(new Date(year, month - 1, day));
                });

                if ((!start?.isValid() || !end?.isValid())) return;
                const keys = [start, end];
                if (start > end) keys[focusedInput === 'start' ? 0 : 1] = null;
                setSelectedKeys(keys);
            };
            const keyDown = (e) => {
                e.stopPropagation();
                if (e.key === 'Tab') pressTab(e);
                if (e.key === 'Enter') pressEnter(e);
            };

            const [focusedInput, setFocusedInput] = useState(null);
            const handleFocus = (input) => {
                setFocusedInput(input.currentTarget.getAttribute('edge'));
            };

            const handleBlur = () => {
                setFocusedInput(null);
            };
            
            return (
                <div ref={rangePickerRef}>
                    <DatePicker.RangePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder={[`${title} от`, 'до']}
                        value={selectedKeys}
                        onChange={setSelectedKeys}
                        format={options.dateFormat}
                        onKeyDown={keyDown}
                        onFocus={(input) => handleFocus(input)}
                        onBlur={handleBlur}
                        focusedInput={focusedInput}
                        style={{ width: 250 }} />
                </div>
               
            );
        }
        default: {
            return (
                <Input
                    placeholder={`Поиск "${title}"`}
                    value={selectedKeys[0]}
                    onChange={e => (setSelectedKeys(e.target.value ? [e.target.value] : []))}
                    onPressEnter={confirm}
                    style={{ width: 180 }} />
            );
        }
    }
};

export default ({ columns = [], ...props }) => {
    const getFilter = (item) => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters
        }) => (
            <div className={cn('filter')}>
                <div className={cn('field')}>
                    <Filter
                        setSelectedKeys={setSelectedKeys}
                        item={item}
                        selectedKeys={selectedKeys}
                        confirm={confirm} />
                </div>
                <Space className={cn('contorls')}>
                    <Button
                        type="primary"
                        onClick={confirm}
                        icon={<FilterFilled />}
                        size="small">
                            Поиск
                    </Button>
                    <Button onClick={() => { clearFilters(); confirm(); }}
                        size="small">
                            Сброс
                    </Button>
                </Space>
            </div>
        )
    });

    const columnItems = React.useMemo(() => columns.map((column) => {
        const { filters, ...props } = column;
        if (typeof filters === 'string') {
            return { ...getFilter(column), ...props };
        } else {
            return column;
        }
    }), [columns]);

    return <Table columns={columnItems} {...props} />;
};