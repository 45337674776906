import { useEffect, useRef, useState } from 'react';

export function useRangePickerSwitcher() {
    const rangePickerRef = useRef(null);
    let rangeInputs = null;
    let inputsWrappers = null;
    const activeClassName = 'ant-picker-input-active';

    useEffect(() => {
        if (!rangePickerRef.current) return;
        inputsWrappers = rangePickerRef.current.querySelectorAll('.ant-picker-input');
        rangeInputs = rangePickerRef.current.querySelectorAll('.ant-picker-input > input');
    });
    
    const pressTab = (e) => {
        const startInFocus = rangeInputs[0] === document.activeElement;
        const focusIndex = startInFocus ? 1 : 0;
        const blurIndex = startInFocus ? 0 : 1;
    
        rangeInputs[blurIndex].blur();
        rangeInputs[focusIndex].focus();
        inputsWrappers[blurIndex].classList.remove(activeClassName);
        inputsWrappers[focusIndex].classList.add(activeClassName);
    };
    
    return [
        rangePickerRef,
        pressTab
    ];
}