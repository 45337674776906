import moment from 'moment';
import { removeEmptyValues } from 'utils/utils';

export const getUrl = (name) => (id) => {
    let url = `/api/${name}`;
    if (id) url += `/${id}`;
    return url;
};

const getMidnight = (dateString, additionalDays = 0) => {
    const date = new Date(dateString);
    const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
    );
    newDate.setDate(newDate.getDate() + additionalDays);
    return moment(newDate).format();
};

export const normilizeFilters = (filters = {}) => Object.keys(filters)
    .reduce((reducer, key) => {
        const value = filters[key];
        let name;

        if (Array.isArray(key)) {
            name = key.join('.');
        } else {
            name = key;
        }

        switch (name) {
            case 'created': {
                const [createdFrom, createdTo] = value;
                return {
                    ...reducer,
                    createdFrom: getMidnight(createdFrom.format()),
                    createdTo: getMidnight(createdTo.format(), 1)
                };
            }
            default: {
                return {
                    ...reducer,
                    [name]: value.join()
                };
            }
        }
    }, {});

export const filter2server = (filter = {}) => {
    const { pages, filters, sort } = filter;
    const filterContent = normilizeFilters(removeEmptyValues(filters));
    const content = { ...pages, ...filterContent };
    if (sort?.field && sort?.order) {
        // eslint-disable-next-line prefer-const
        let { field: orderField, order: orderType } = sort;
        if (Array.isArray(orderField)) {
            orderField = orderField.join('.');
        }
        content.orderField = orderField;
        content.orderType = orderType;
    }

    delete content.total;

    return {
        ...removeEmptyValues(content)
    };
};

export const getDateFromNow = (addedDays, dateFormat) => {
    const currentDate = new Date();
    return moment((currentDate)
        .setDate(currentDate.getDate() + addedDays))
        .format(dateFormat);
};

export const zeroDate = moment(new Date(2000, 0, 1));