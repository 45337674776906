import React from 'react';
import { Layout } from 'antd';
import bem from 'easy-bem';

import './style.less';

const cn = bem('logo');

export default ({ children, ...props }) => (
    <img className={cn()} src={process.env.LOGO_PATH} alt="Логотип" {...props} />
);
