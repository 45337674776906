import React, { Component, Suspense } from 'react';
import bem from 'easy-bem';

import Content from 'components/content';
import Loader from 'components/suspense-loader';

import './style.less';

const Auth = React.lazy(() => import('./forms/auth'));
const cn = bem('auth');

export default () => (
    <div className={cn()}>
        <Suspense fallback={<Loader />}>
            <Content className={cn('body')}>
                <Auth />
            </Content>
        </Suspense>
    </div>
);