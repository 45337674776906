import React from 'react';
import { Button, Layout, Space, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import bem from 'easy-bem';
import auth from 'contexts/auth';
import { HEADER } from './constants';

import './style.less';

const {
    Header, Content
} = Layout;

const cn = bem('header');

const getHeader = (path) => {
    const text = HEADER[path];

    if (text) {
        return text;
    } else {
        /* eslint-disable */
        for (const pathname in HEADER) {
            if (path.includes(pathname)) {
                return HEADER[pathname];
            }
        }
        /* eslint-enable */
        return '';
    }
};

export default () => {
    const { user, onLogout } = React.useContext(auth);

    const { pathname } = useLocation();

    return (
        <Header className={cn()}>
            {/* <div className={cn('text')}>{getHeader(pathname)}</div> */}
            <Typography.Title level={4}>
                {getHeader(pathname)}
            </Typography.Title>
            <Button onClick={onLogout} type="primary"> Выход </Button>
        </Header>
    );
};