const wrongValue = 'Недопустимое значение';

export const everyStartsValidator = (value, otherValue) => {
    if (value === undefined) return Promise.reject('Поле не задано');
    if (value < 1 || value > 7) return Promise.reject(wrongValue);
    if (otherValue === undefined) return Promise.resolve();
    if (value * otherValue > 7) {
        return Promise.reject(wrongValue);
    }
    return Promise.resolve();
};