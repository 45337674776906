import axios from 'axios';
import { object2Multipart, getToken } from 'utils/utils';

/**
 * Получение списка элементов
 * @returns {Promise}
 */
export const list = (state) => axios.get('/api/interval_analysis', { params: state });

/**
 * Получение переодического анализа
 * @returns {Promise}
 */
export const get = (id) => axios.get(`/api/interval_analysis/${id}`);

/**
 * Создание переодического анализа
 * @param body
 * @returns {Promise}
 */
export const create = (body) => {
    const content = object2Multipart(body);
    return axios.post('/api/interval_analysis/create', content);
};

/**
 * Редактирование переодического анализа
 * @param body
 * @returns {Promise}
 */
export const change = (id, body) => {
    const content = object2Multipart(body);
    const result = axios.patch(`/api/interval_analysis/${id}`, content);
    return result;
};

/**
 * Запуск переодического анализа
 * @param id
 * @param isForce
 * @returns {Promise}
 */
export const start = async (id, isForce) => axios.post('/api/interval_analysis/start', { id, force: isForce });

/**
 * Остановка переодического анализа
 * @param id
 * @param isForce
 * @returns {Promise}
 */
export const stop = async (id) => axios.post('/api/interval_analysis/stop', { id });

/**
 * Создание дубликата переодического анализа
 * @param id
 * @returns {Promise}
 */
export const copy = async (id) => axios.post(`/api/interval_analysis/copy/${id}`);

/** Удаление переодического анализа
 *
 * @param id
 * @returns {Promise}
 */
export const remove = async (id) => axios.delete(`/api/interval_analysis/${id}`);

/** Получение списка текущих анализов
 *
 * @param id
 * @returns {Promise}
 */
export const predictions = async (id) => axios.get(`/api/interval_analysis/${id}/current_predictions`);

/** Получение списка текущих анализов
 *
 * @param id
 * @param prediction_date
 * @returns {Promise}
 */
export const primaryList = async (id, prediction_date) => axios.get(`/api/interval_analysis/${id}/analyzes/${prediction_date}`);

/**
 * Скачивание результатов (Формирование ссылки)
 * */
export const downloadResultsUrl = (id, predictionDate) => `/api/interval_analysis/${id}/download/${predictionDate}?token=${getToken()}`;
