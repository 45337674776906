import { TYPES, CREATION_TYPES } from './constants';

export const getCreationType = (analysis_type) => {
    switch (analysis_type) {
        case TYPES.ARABLE:
        case TYPES.TRACK:
        case TYPES.WOOD:
            return CREATION_TYPES.PROCESSING;
        case TYPES.FIRE:
            return CREATION_TYPES.ARBITRARY;
        default:
            return '';
    }
};