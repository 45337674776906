function splitDate(momentValue) {
    return momentValue.format('YYYY-MM-DD').split('-').map(item => Number(item));
}

export const periodValidator = (rule, value) => {
    const [from_date, to_date] = value;
    const [fromYYYY, fromMM, fromDD] = splitDate(from_date);
    const [toYYYY, toMM, toDD] = splitDate(to_date);
    const errorText = 'Укажите дату больше на один год от выбранной даты в поле "От"';
    
    if (toYYYY - fromYYYY < 1) return Promise.reject(errorText);
    if (toYYYY - fromYYYY === 1) {
        if (fromMM > toMM) return Promise.reject(errorText);
        if (toMM === fromMM && fromDD > toDD) return Promise.reject(errorText);
    }

    return Promise.resolve();
};

export const inputValidator = (rule, value) => {
    if (!value.trim()) return Promise.reject('Поле не задано');
    return Promise.resolve();
};

export const isSentinelOnly = (processing_type) => [
    'arable',
    'forest',
    'hogweed',
    'arable_cert',
    'reforestation'
].includes(processing_type);