import axios from 'axios';

import { getUrl, filter2server } from '../utils';

const url = getUrl('auth');

/**
 * Авторизация пользователя
 * @param username
 * @param password
 * @returns {Promise}
 */
export const login = ({ username, password }) => axios.post('/api/login', {
    login: username,
    password
});

/**
 * Выход из системы
 * @returns {Promise}
 */
export const logout = () => axios.post('/api/logout');

/**
 * Проверка авторизации
 * Получение текущего пользователя
 * @returns {Promise}
 */
export const current = () => axios.get('/api/users/current');

/**
 * Список пользователей
 * @returns {Promise}
 */
export const list = (state) => axios.get('/api/users/list', { params: filter2server(state) });

/**
 * Добавить пользователя
 * @returns {Promise}
 */
export const create = (body) => axios.post('/api/users', body);

/**
 * Редактировать пользователя
 * @returns {Promise}
 */
export const edit = (id, body) => axios.patch(`/api/users/${id}`, body);

/**
 * Удалить пользователя
 * @returns {Promise}
 */
export const remove = (id) => axios.delete(`/api/users/${id}`);
