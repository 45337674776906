/**
 * Преобразует id и version в ключ
 * @param id
 * @param version
 * @returns {string}
 */
export const getKey = (id, version) => (`${id}_${version}`);

export const setToken = (token) => window.localStorage.setItem('access_token', token);
export const getToken = () => window.localStorage.getItem('access_token');

/**
 * Возвращает id и version из ключа
 * @param key
 * @returns {void|*|string[]|[]} [id, version]
 */
export const getIdVersion = key => (key.split('_'));

export const object2Multipart = (body) => {
    // const content = removeEmptyValues4Multipart(body);
    const formData = new FormData();
    Object.keys(body).forEach((key) => {
        if (body[key] !== undefined) {
            formData.append(key, body[key]);
        }
    });
    return formData;
};

/**
 * Парсинг объекта по пути
 * @param object
 * @param path
 * @returns {*}
 */
export const getValueByObjectPath = (object, path = []) => {
    if (!path.length) return object;
    path.forEach(
        (element, index) => {
            object = object[element] || (path.length - 1 === index ? undefined : {});
        }
    );
    return object;
};

const now = () => {
    const time = Date.now();
    const last = now.last || time;
    return now.last = time > last ? time : last + 1;
};

/**
 * Генерит уникальный идентификатор
 * @returns {*}
 */
export const uid = () => now().toString(36);

/**
 * Проверяет Объект {} на пустоту
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * Возвращает базовое имя файла без пути
 * @param {string} filepath - имя файла с путём
 * @returns string
 */
export const getBaseName = filepath => filepath.match(/[^/\\]*$/)[0];

/**
 *
 */
export const isRequired = () => {
    throw new Error('Required function parameter is empty');
};

const takeLatestStorage = {};
/**
 * Функция для оптимизации обработки многократных вызовов
 * @param id - идентификатор данной операции.
 * @param cb - callback выполнения операции
 * @param timeout - таймер операции (50 мс по умолчанию)
 * @returns {*}
 */
export const takeLatest = (id, cb, timeout = 50) => {
    const timerId = takeLatestStorage[id];
    if (timerId) {
        clearInterval(timerId);
    }
    takeLatestStorage[id] = setTimeout(() => {
        clearInterval(timerId);
        cb();
    }, timeout);
    return id;
};

export const removeEmptyValues4Multipart = (rawObject) => {
    const object = {};
    Object.keys(rawObject)
        .forEach((key) => {
            const rawElement = rawObject[key];
            const elementType = typeof rawElement;
            switch (elementType) {
                case 'undefined': {
                    break;
                }
                default: {
                    object[key] = rawElement;
                }
            }
        });
    return object;
};

export const removeEmptyValues = (rawObject = {}) => {
    const object = {};
    Object.keys(rawObject).forEach((key) => {
        const rawElement = rawObject[key];
        const elementType = typeof rawElement;
        switch (elementType) {
            case 'undefined': {
                break;
            }
            case 'object': {
                if (rawElement == null) {
                    break;
                }
                if (Array.isArray(rawElement)) {
                    object[key] = rawElement;
                } else {
                    object[key] = removeEmptyValues(rawElement);
                }
                break;
            }
            default: {
                object[key] = rawElement;
            }
        }
    });
    return object;
};

/**
 * Функция нахождения разницы между двумя объектами, без рекурсии
 * @param after
 * @param before
 * @param emptyValue
 * @returns {{}}
 */
export const getDiff = (after, before, emptyValue) => {
    const beforeKeys = Object.keys(before);
    const afterKeys = Object.keys(after);
    const result = {};
    afterKeys.forEach((key) => {
        const afterValue = after[key];
        const beforeValue = before[key];
        if (beforeValue !== afterValue) {
            result[key] = afterValue;
        }
    });
    beforeKeys.forEach((key) => {
        const afterValue = after[key];
        if (afterValue === undefined) {
            result[key] = emptyValue;
        }
    });
    return result;
};