import React from 'react';
import { Menu, Layout } from 'antd';
import bem from 'easy-bem';
import { useLocation, useHistory, useRouteMatch } from 'react-router';

import {
    FundProjectionScreenOutlined,
    UserOutlined,
    SettingOutlined,
    BarChartOutlined,
    MonitorOutlined
} from '@ant-design/icons';

import Logo from 'components/logo';
import './style.less';

const { Sider } = Layout;

const cn = bem('menu');

export default () => {
    const { push } = useHistory();
    const { path } = useRouteMatch();
    const [, location] = path.split('/');

    const onClick = (event) => {
        const { key } = event;
        push(`/${key}`);
    };

    const items = [
        {
            label: 'Пользователи',
            key: 'users',
            icon: (<UserOutlined style={{ fontSize: '24px' }} />)
        },
        {
            label: 'Обработчики',
            key: 'workers',
            icon: (<SettingOutlined style={{ fontSize: '24px' }} />)
        },
        {
            label: 'Анализ',
            key: 'analysis',
            icon: (<BarChartOutlined style={{ fontSize: '24px' }} />)
        },
        {
            label: (<div>Периодический<br />анализ</div>),
            key: 'interval-analysis',
            icon: (<MonitorOutlined style={{ fontSize: '24px' }} />)
        }
    ];

    return (
        <Sider className={cn()}>
            {/* <div className={cn('logo')}> */}
            {/*    <Logo /> */}
            {/* </div> */}
            <Menu
                onClick={onClick}
                theme="dark"
                mode="inline"
                selectedKeys={[location]}>
                {items.map(item => (
                    <Menu.Item
                        key={item.key}
                        icon={item.icon}
                        style={{
                            lineHeight: '140%',
                            paddingBlock: '12px',
                            height: 'fit-content',
                            fontSize: '16px'
                        }}>
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
};
