import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import Auth from 'contexts/auth';

export default ({
    redirectPath = '/', component: Component, roles = [], isGuestOnly, ...rest
}) => {
    const { user } = useContext(Auth);
    const { id } = user;
    return (
        <Route {...rest}
            render={(props) => {
                if (id || (!id && isGuestOnly)) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={redirectPath} />;
                }
            }} />
    );
};