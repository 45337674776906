import React from 'react';
import { Switch, Redirect } from 'react-router';
import * as Pages from './pages';

export default (
    <Switch>
        { Object.values(Pages) }
        {/*<Redirect exact to="/" />*/}
    </Switch>
);
