import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';

import Auth from 'contexts/auth';

const route = () => {
    const { user } = useContext(Auth);

    let redirectUrl;

    if (user?.id) {
        redirectUrl = '/workers';
    } else {
        redirectUrl = '/auth';
    }

    return (
        <Redirect exact from="/" to={redirectUrl} />
    );
};

export default <Route
    exact
    key="home"
    path="/"
    component={route} />;
