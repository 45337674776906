import React from 'react';
import { useParams, useHistory } from 'react-router';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import moment from 'moment';

import { get } from 'models/interval-analysis/api';

import Item from './item';

const cn = bem('analysis-item');

export default () => {
    const [item, setItem] = React.useState();
    const { id } = useParams();
    const { push } = useHistory();

    const init = async () => {
        try {
            const { data } = await get(id);
            const item = { ...data };

            item.target_date = [
                moment(item.from_date),
                moment(item.to_date)
            ];

            item.current_area_of_interest = item.area_of_interest;
            delete item.area_of_interest;

            setItem(item);
        } catch (e) {
            push('/interval-analysis');
        }
    };

    React.useEffect(() => {
        init();
    }, []);

    if (item) {
        return <Item item={item} />;
    }

    return (
        <Spin className={cn('loader')} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
    );
};
