import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import bem from 'easy-bem';
import moment from 'moment';
import {
    start, downloadResultsUrl
} from 'models/analysis/api';
import {
    Breadcrumb,
    Dropdown,
    Menu,
    Popconfirm,
    Space,
    Table
} from 'antd';
import {
    ArrowLeftOutlined,
    DownloadOutlined,
    LoadingOutlined,
    PlayCircleOutlined,
    RedoOutlined
} from '@ant-design/icons';
import {
    STATUS,
    STATUS_LOC,
    TYPES_LOC
} from '../../../../models/analysis/constants';
import { BREADCRUMBS } from '../../../../models/interval-analysis/constants';
import { primaryList } from '../../../../models/interval-analysis/api';
import options from '../../../../options';

const cn = bem('interval-analysis-primary');

const reducer = (state, action) => {
    switch (action.type) {
        case 'status-changed': {
            const { id, status } = action.payload;
            return state.map(item => {
                if (item.id === id) {
                    item.status = status;
                }
                return item;
            });
        }
        case 'updated': {
            return action.payload;
        }
        default: {
            throw new Error('There is no such reduce action');
        }
    }
};

export default () => {
    const history = useHistory();

    const [isLoading, setLoading] = useState(true);
    const [data, dispatch] = useReducer(reducer, []);

    const { id, prediction_date } = useParams();

    const onUpdate = async (showLoader = true) => {
        try {
            setLoading(showLoader);
            const { data } = await primaryList(id, prediction_date);
            dispatch({
                type: 'updated',
                payload: data.values
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onUpdate();

        const interval = setInterval(() => {
            onUpdate(false);
        }, options.processingUpdateInterval);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const AVAILABLE_ACTIONS = {
        start: [STATUS.CREATED],
        download: [STATUS.DONE],
        restart: [STATUS.FAILURE],
        loading: [STATUS.LOADING]
    };

    const DOWNLOAD_MENU_ITEMS = {
        detection: 'Shape',
        geojson: 'GeoJSON',
        rgb_new: 'RGB new',
        rgb_base: 'RGB base',
        multi_temporal: 'Multi temporal'
    };

    const isActionAvailable = (action, text) => AVAILABLE_ACTIONS[action]?.includes(text);

    const onStatusChange = (id, status) => {
        dispatch({
            type: 'status-changed',
            payload: {
                id,
                status
            }
        });
    };

    const onStart = (id, isForced) => async (e) => {
        e.stopPropagation();
        try {
            onStatusChange(id, 'loading');
            await start(id, isForced);
        } finally {
            onUpdate();
        }
    };

    const onDownload = (links, key) => {
        window.open(downloadResultsUrl(key, links));
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 70
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true
        },
        {
            title: 'Тип',
            dataIndex: 'analysis_type',
            key: 'analysis_type',
            render: (text) => TYPES_LOC[text],
            width: 300
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 130,
            render: (text) => STATUS_LOC[text]
        },
        {
            title: 'Дата и время создания',
            dataIndex: 'created',
            key: 'created',
            width: 170,
            render: (text) => moment(text).format(options.dateTimeFormat)
        },
        {
            title: 'Управление',
            dataIndex: 'control',
            key: 'control',
            width: 176,
            align: 'right',
            render: (text, row) => (
                <Space className={cn('actions')}>
                    {isActionAvailable('download', text) && (
                        <div>
                            <Dropdown overlay={(
                                <Menu onClick={(e) => {
                                    onDownload(row.files, e.key);
                                }}>
                                    {
                                        Object.keys(row.files).map((key) => (
                                            <Menu.Item key={key}>
                                                {DOWNLOAD_MENU_ITEMS[key]}
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu>
                            )}>
                                <DownloadOutlined title="Скачать" />
                            </Dropdown>
                        </div>
                    )}
                    {isActionAvailable('start', text) && (
                        <PlayCircleOutlined title="Запустить" onClick={onStart(row.id)} />
                    )}
                    {isActionAvailable('restart', text) && (
                        <Popconfirm
                            title="Перезапустить анализ?"
                            onConfirm={onStart(row.id, true)}
                            okText="Да"
                            cancelText="Нет">
                            <RedoOutlined title="Перезапустить" />
                        </Popconfirm>
                    )}
                    { isActionAvailable('loading', text) && <LoadingOutlined style={{ fontSize: 24 }} spin primary /> }
                </Space>
            )

        }
    ];

    const transformData = (item, idx) => {
        const objToReturn = {
            key: idx,
            ...item,
            control: item.status,
            files: item.files
        };

        return objToReturn;
    };

    const breadcrumbs = Object.values(BREADCRUMBS);

    return (
        <div className={cn()}>
            <Space className={cn('breadcrumbs')}>
                <ArrowLeftOutlined className={cn('breadcrumb-icon')} onClick={() => history.goBack()} />
                <Breadcrumb>
                    {
                        breadcrumbs.map((item, idx) => {
                            if (idx === breadcrumbs.length - 1) {
                                return (<Breadcrumb.Item key={item.name}>{ item.name }</Breadcrumb.Item>);
                            } else {
                                return (<Breadcrumb.Item key={item.name}><Link to={item.getUrl(useParams())}>{ item.name }</Link></Breadcrumb.Item>);
                            }
                        })
                    }
                </Breadcrumb>
            </Space>
            <div className={cn('content')}>
                <Table
                    key="id"
                    rowClassName={cn('row')}
                    loading={isLoading}
                    columns={columns}
                    dataSource={data.map(transformData)} />
                    
            </div>
        </div>
    );
};
